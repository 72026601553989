import React, { useEffect, useState } from 'react'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'

const Container = styled.div`
  text-align: center;
`

const Badge = styled.i`
  font-size: 18px;
  /* color: var(--primary); */
  filter: brightness(1.1);
  margin: 0px 4px;
`

const Badges = ({ value, color }) => {
  const [badges, setBadges] = useState([])

  const api = useTalentApi()

  useEffect(() => {
    api.get('badges').then(({ data }) => setBadges(data))
  }, [api])

  const getBadge = (id) => badges.find((x) => x._id === id) || {}

  return (
    <Container>
      {(value || []).map((id) => (
        <Popup
          key={id}
          trigger={
            <Badge
              className={getBadge(id).icon}
              style={{ color: getBadge(id).color }}
            />
          }
          content={getBadge(id).name}
          position="bottom left"
        />
      ))}
    </Container>
  )
}

export default Badges
