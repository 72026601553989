import React, { useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import POWERS from '../../powers'
import AddContractModal from '../modals/addContractModal'
import TerminateContractModal from '../modals/terminateContractModal'
import UpdateDealModal from '../modals/updateDealModal'
import RequirePowers from '../requirePowers'

const ContractsMenu = ({
  contracts,
  contractId,
  candidate,
  onContractTerminated,
  onShowHistory,
  onContractAdded,
  onDealUpdated,
}) => {
  const [showTerminateModal, setShowTerminateModal] = useState(false)
  const [showAddContractModal, setShowAddContractModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const activeContract = contracts.find((x) => !x.endDate)

  return (
    <React.Fragment>
      <Dropdown
        icon={null}
        trigger={<Icon name="ellipsis horizontal" />}
        style={{ marginRight: 10 }}
        basic
        pointing="top right"
      >
        <Dropdown.Menu>
          <RequirePowers powers={[POWERS.createContract]}>
            <Dropdown.Item
              text="Add New Contract"
              icon="add"
              disabled={!!activeContract}
              onClick={() => setShowAddContractModal(true)}
            />
          </RequirePowers>

          <Dropdown.Item
            text="View History"
            icon="history"
            onClick={onShowHistory}
          />

          <RequirePowers powers={[POWERS.updateDeal, POWERS.customerSOWs]}>
            <Dropdown.Divider />
            <Dropdown.Item
              text="Update Contract"
              icon="edit outline"
              onClick={() => setShowUpdateModal(true)}
              disabled={!activeContract}
            />
          </RequirePowers>

          <RequirePowers powers={[POWERS.terminateContract]}>
            <Dropdown.Divider />
            <Dropdown.Item
              disabled={!activeContract}
              content={
                <div style={{ color: 'var(--red)' }}>
                  <b>
                    <Icon name="cut" />
                    Terminate Contract
                  </b>
                </div>
              }
              onClick={() => setShowTerminateModal(true)}
            />
          </RequirePowers>
        </Dropdown.Menu>
      </Dropdown>

      <AddContractModal
        show={showAddContractModal}
        candidate={candidate}
        onCancel={() => setShowAddContractModal(false)}
        onSuccess={() => {
          setShowAddContractModal(false)
          onContractAdded()
        }}
      />

      <UpdateDealModal
        show={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        contractId={contractId}
        onSuccess={() => {
          setShowUpdateModal(false)
          onDealUpdated()
        }}
      />

      <TerminateContractModal
        candidate={candidate}
        show={showTerminateModal}
        onCancel={() => setShowTerminateModal(false)}
        contractId={contractId}
        onSuccess={() => {
          setShowTerminateModal(false)
          onContractTerminated()
        }}
      />
    </React.Fragment>
  )
}

export default ContractsMenu
