import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PageLoader from '../../components/pageLoader'
import { useTalentApi } from '../../store/mainContext'
import { Link, useParams } from 'react-router-dom'
import CandidateSidePanel from '../../components/sidepanels/candidateSidePanel.js'
import {
  Breadcrumb,
  // , Button, Icon
} from 'semantic-ui-react'
import Feed from '../../components/feeds/feed'
import CandidateActions from '../../components/actions/candidateActions'
import PositionLinker from '../../components/positionLinker'
import CandidateDuplicate from '../../components/candidateDuplicate'

import FunnelBar from '../../components/funnelBar/funnelBar'

import RequirePowers from '../../components/requirePowers'

import LinkedinViewer from '../../components/linkedinViewer'
import Contracts from '../../components/contracts/contracts'
import POWERS from '../../powers'
import Submissions from '../../components/submissions/submissions'
import DateFormat from '../../components/dateFormat'
import CompanyName from '../../components/remoteValues/companyName'
// import SideAddButton from "../../components/sidepanel/sideAddButton";

const Container = styled.div`
  display: flex;
  justify-content: space-between !important;
`

const MiddleColumn = styled.div`
  width: 100%;
  padding: 20px 20px;

  /* max-width: 600px; */
  min-width: 500px; /* background-color: var(--bg-grey); */
`

const LeftColumn = styled.div`
  margin-left: 10px;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  max-height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  z-index: 900;

  padding-bottom: 100px;
  /* border-right: dotted 1px var(--border-grey); */
  padding-right: 10px;

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`

const Title = styled.h1`
  margin: 15px 20px 0px 30px !important;
`

const Sub = styled.div`
  margin: 5px 20px 0px 32px !important;
  font-size: 15px;
  color: #555;
`

// const StickyContainer = styled.div`
//   position: sticky;
//   top: 0px;
//   background-color: #fff;
//   z-index: 1000;
// `

const Candidate = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [candidate, setCandidate] = useState()
  const [contract, setContract] = useState()
  // const [showPosInfo, setShowPosInfo] = useState(true)

  const { candidateId } = useParams()

  const api = useTalentApi()

  useEffect(() => {
    api
      .get(`candidates/${candidateId}`)
      .then(({ data }) => setCandidate(data))
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }, [candidateId, api])

  useEffect(() => {
    if (!candidate) return
    document.title = `${candidate.name}`
  }, [candidate])

  const handleCandidateChange = (name, value) => {
    const newCandidate = { ...candidate }
    newCandidate[name] = value
    newCandidate.lastUpdateOn = Date.now()
    setCandidate(newCandidate)
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (!candidate) {
    document.location.href = '/candidates'
    return null
  }

  return (
    <React.Fragment>
      <Container>
        <LeftColumn>
          <Breadcrumb style={{ padding: 15, marginLeft: 25 }}>
            <Breadcrumb.Section>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right chevron" />

            <Breadcrumb.Section>
              {!candidate.status && <Link to="/candidates">Candidates</Link>}

              {candidate.status && (
                <Link
                  to={`/candidates/funnels/${candidate.status.split('.')[0]}`}
                >
                  Funnel
                </Link>
              )}
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right chevron" />

            <Breadcrumb.Section active>
              {candidate.name.split(' ')[0]}
            </Breadcrumb.Section>
          </Breadcrumb>

          <Title>{candidate.name}</Title>

          {contract && contract.active ? (
            <React.Fragment>
              <Sub>{candidate.location}</Sub>
              <Sub>
                With us{' '}
                <b>
                  since <DateFormat date={contract.startingDate} />{' '}
                </b>
                (<CompanyName id={contract.customerId} />)
              </Sub>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Sub>{candidate.location}</Sub>
              {candidate.referal && <Sub>Referred by {candidate.referal}</Sub>}
            </React.Fragment>
          )}

          {/* <div>{JSON.stringify(contract)}</div> */}

          <CandidateActions
            candidate={candidate}
            contract={contract}
            onSuccess={handleCandidateChange}
          />

          <CandidateDuplicate candidate={candidate} />

          <FunnelBar
            key={candidate.status}
            entity={candidate}
            collection="candidates"
            onSuccess={handleCandidateChange}
          />

          <React.Fragment>
            <PositionLinker
              key={candidate.positionId}
              candidate={candidate}
              onSuccess={handleCandidateChange}
            />

            <Submissions
              candidateId={candidate._id}
              positionId={candidate.positionId}
            />
          </React.Fragment>

          {/* <TimeOffRequests
            candidate={candidate}
            onSuccess={handleCandidateChange}
          /> */}

          <RequirePowers powers={[POWERS.contracts]}>
            <Contracts
              candidate={candidate}
              onSelectedContractChange={(c) => setContract(c)}
            />
          </RequirePowers>

          {/* <RequirePowers powers={["deals"]}>
            <Deal
              key={candidate.dealId}
              candidate={candidate}
              onChangeSuccess={handleCandidateChange}
            />
          </RequirePowers>

          <Offer
            key={candidate.offerId}
            candidate={candidate}
            onChangeSuccess={handleCandidateChange}
          /> */}

          <CandidateSidePanel
            key={candidate.lastUpdateOn}
            candidate={candidate}
            onChangeSuccess={handleCandidateChange}
          />
        </LeftColumn>

        <MiddleColumn>
          <Feed relatedId={candidate._id} relatedCollection="candidates" />
        </MiddleColumn>

        <LinkedinViewer url={candidate.linkedin} />
      </Container>
    </React.Fragment>
  )
}

export default Candidate
