import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useAuthApi, useTalentApi } from '../../store/mainContext'

import UserSelect from '../form/userSelect'
import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'
import styled from 'styled-components'

const UserImage = styled.img`
  border-radius: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 10px;
`

const UserName = styled.div`
  display: flex;
  align-items: center;
`

const TrUserSelect = ({
  name,
  label,
  placeholder,
  value,
  onSuccess,
  url,
  render,

  divider,
  color,
}) => {
  const authApi = useAuthApi()
  const api = useTalentApi()

  const [showEdit, setShowEdit] = useState(false)

  const [editMode, setEditMode] = useState(false)
  const [val, setVal] = useState(value)
  const [showAdd, setShowAdd] = useState(false)

  const [displayValue, setDisplayValue] = useState()

  const [isLoading, setIsLoading] = useState(false)

  const [, setCancelOnBlur] = useState(false)

  const refreshDisplayValue = React.useCallback(
    async (newvalue) => {
      if (!newvalue) {
        setDisplayValue(null)
        return
      }
      const { data } = await authApi.get(`users/${newvalue}`)
      setDisplayValue(data)
    },
    [authApi]
  )

  useEffect(() => {
    setVal(value)
    refreshDisplayValue(value)
  }, [value, refreshDisplayValue])

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj = {}
    obj[name] = val

    if (!val || val === '' || val.length === 0) {
      await api.delete(`${url}/${name}`)
    } else {
      await api.patch(url, obj)
    }

    await refreshDisplayValue(val)

    setIsLoading(false)
    setEditMode(false)
    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setEditMode(false)
    setIsLoading(false)
  }

  if (editMode) {
    return (
      <tr
      // className="solidBackground"
      // onBlur={(x) => !cancelOnBlur && handleCancel()}
      >
        <th className={divider && 'solidBorderTop'}>{label}</th>
        <td
          className={divider && 'solidBorderTop'}
          style={{
            paddingTop: 4,
            textAlign: `right`,
          }}
        >
          <UserSelect
            onChange={(e, t) => {
              setVal(t.value)
            }}
            name={name}
            fluid={true}
            placeholder={placeholder}
            value={val}
            disabled={isLoading}
          />

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={(x) => setCancelOnBlur(true)}
              onMouseOut={(x) => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={(x) => setCancelOnBlur(true)}
              onMouseOut={(x) => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (!val) {
    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
      >
        <th>{label}</th>
        <td>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{showAdd && <a>+ Add value</a>}&nbsp;</a>
        </td>
      </tr>
    )
  }

  return (
    <tr
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
    >
      <th>{label}</th>
      <td style={{ position: 'relative' }}>
        {displayValue && (
          <UserName>
            <UserImage src={displayValue.image} />
            {displayValue.name}
          </UserName>
        )}

        {/* <ShowIf if={displayValue}>
          
          {displayValue.name}
        </ShowIf> */}

        <TrCopyButton
          visible={showEdit}
          value={displayValue && displayValue.name}
        />
        <TrEditButton onClick={() => setEditMode(true)} visible={showEdit} />
      </td>
    </tr>
  )
}

export default TrUserSelect
