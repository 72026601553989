import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PageLoader from '../../components/pageLoader'
import { useTalentApi } from '../../store/mainContext'
import { Link, useParams } from 'react-router-dom'
import { Breadcrumb, Menu } from 'semantic-ui-react'
import Feed from '../../components/feeds/feed'
import CompanySidePanel from '../../components/sidepanels/companySidePanel'

import CompanyActions from '../../components/actions/companyActions'
import LinkedinViewer from '../../components/linkedinViewer'
import ShowIf from '../../components/showIf'
import ContractsDetail from '../../components/contractsDetail'
import RequirePowers from '../../components/requirePowers'
import POWERS from '../../powers'
import ContactsDetail from '../../components/contactsDetail'

const Container = styled.div`
  display: flex;
  justify-content: space-between !important;
`

const MiddleColumn = styled.div`
  width: 100%;
  padding: 20px 20px;

  /* max-width: 600px; */
  min-width: 500px; /* background-color: var(--bg-grey); */
`

const LeftColumn = styled.div`
  margin-left: 10px;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  max-height: 100vh;
  /* overflow: auto; */
  /* position: sticky; */
  top: 0;

  padding-bottom: 100px;
  /* border-right: dotted 1px var(--border-grey); */
  padding-right: 10px;

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`

const Frame = styled.iframe`
  width: 100%;
  border: dotted 1px var(--border-grey);
  height: 90vh;
  padding-top: -25px;
  border-radius: 4px;
`

const Title = styled.h1`
  margin: 15px 20px 0px 30px !important;
`

const Sub = styled.div`
  margin: 5px 20px 0px 30px !important;
  font-size: 15px;
  color: #555;
`

// const CriticalAlert = styled.div`
//   margin: 15px 20px 0px 30px !important;
//   border: dotted 1px #ffe1e1;
//   padding: 10px;
//   border-radius: 4px;
//   background-color: #ffe9e9;
//   color: var(--red);
//   text-align: center;
// `;

const Company = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [selectedTab, setSelectedTab] = useState('feed')

  const [company, setCompany] = useState()

  const { companyId } = useParams()

  const api = useTalentApi()

  useEffect(() => {
    api
      .get(`companies/${companyId}`)
      .then(({ data: c }) => setCompany(c))
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }, [api, companyId])

  useEffect(() => {
    if (!company) return
    document.title = `${company.name}`
  }, [company])

  const handleOnChange = (name, value) => {
    const newObj = { ...company }
    newObj[name] = value
    setCompany(newObj)
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (!company) {
    document.location.href = '/companies'
    return null
  }

  return (
    <React.Fragment>
      <Container>
        <LeftColumn>
          <Breadcrumb style={{ padding: 15, marginLeft: 25 }}>
            <Breadcrumb.Section link>
              <Link to="/dashboard">Dashboard</Link>
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right chevron" />

            <Breadcrumb.Section link>
              {!company.status && <Link to="/companies">Companies</Link>}

              {company.status && (
                <Link to={`/companies/funnels/${company.status.split('.')[0]}`}>
                  Funnel
                </Link>
              )}
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right chevron" />

            <Breadcrumb.Section active>{company.name}</Breadcrumb.Section>
          </Breadcrumb>

          <Title>{company.name}</Title>

          <ShowIf if={company.sector}>
            <Sub>
              <b>{company.sector}</b>
            </Sub>
          </ShowIf>

          <ShowIf if={company.location}>
            <Sub>
              <b>{company.location}</b>
            </Sub>
          </ShowIf>

          <CompanyActions company={company} onSuccess={handleOnChange} />

          <CompanySidePanel
            company={company}
            onChangeSuccess={handleOnChange}
          />
        </LeftColumn>

        <MiddleColumn>
          <RequirePowers powers={[POWERS.contracts]}>
            <Menu tabular>
              <Menu.Item
                icon="feed"
                name="Feed"
                active={selectedTab === 'feed'}
                onClick={() => setSelectedTab('feed')}
              />

              <Menu.Item
                icon="users"
                name="Contacts"
                active={selectedTab === 'contacts'}
                onClick={() => setSelectedTab('contacts')}
              />

              <RequirePowers powers={[POWERS.customerSOWs]}>
                <Menu.Item
                  icon="address card outline"
                  name="Rate Card"
                  active={selectedTab === 'rate-card'}
                  onClick={() => setSelectedTab('rate-card')}
                  disabled={!company.rateCard}
                />
              </RequirePowers>

              <Menu.Item
                icon="file alternate outline"
                name="Contracts"
                active={selectedTab === 'candidates'}
                onClick={() => setSelectedTab('candidates')}
              />
            </Menu>
          </RequirePowers>

          {selectedTab === 'feed' && (
            <Feed relatedId={company._id} relatedCollection="companies" />
          )}

          {selectedTab === 'contacts' && (
            <ContactsDetail companyId={company._id} />
          )}

          {selectedTab === 'candidates' && (
            <ContractsDetail
              contractsFilter={{ customerId: company._id }}
              submissionsFilter={{ customerId: company._id }}
            />
          )}

          {selectedTab === 'rate-card' && company.rateCard && (
            <Frame src={company.rateCard} onLoad={() => setIsLoading(false)} />
          )}
        </MiddleColumn>

        {selectedTab === 'feed' && <LinkedinViewer url={company.linkedin} />}
      </Container>
    </React.Fragment>
  )
}

export default Company
