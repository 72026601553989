import * as React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Btn = styled(Button)`
  padding: 7px !important;
  font-size: 8px !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 100px !important;
`

const CheckButton = ({ value, onChange, disabled, onMouseUp }) => {
  if (!value) {
    return (
      <Btn
        onMouseUp={onMouseUp}
        basic
        icon
        onClick={!disabled && onChange}
        size="mini"
      >
        <Icon name="" />
      </Btn>
    )
  }

  return (
    <Btn
      onMouseUp={onMouseUp}
      color="black"
      icon
      onClick={!disabled && onChange}
      size="mini"
    >
      <Icon name="check" />
    </Btn>
  )
}

export default CheckButton
