import React, {
  // useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { Dropdown, DropdownDivider, Icon } from 'semantic-ui-react'

import CheckButton from '../../components/form/checkButton'
import UserName from '../../components/remoteValues/userName'

import { getDiffInDays, timeout, isToday, isBeforeToday } from '../../utils'

import { useTalentApi } from '../../store/mainContext'

import CompleteTaskModal from '../../components/modals/completeTaskModal'

const TaskItemContainer = styled.div`
  display: flex;
  align-items: top;
  padding: 3px 15px 2px 0px;
`

const TaskTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`

const TaskDetail = styled.div`
  white-space: break-spaces;
  max-width: 300px;
  font-size: 12px;
  line-height: 16px;
  /* background-color: yellow; */
  padding: 0px;
  margin-bottom: 15px;
`

const TasksIco = styled(Icon)`
  font-size: 17px !important;
  color: #858585;

  &:hover {
    filter: brightness(1.5);
  }
`

const TasksMenu = ({
  tasks,
  style,
  onScheduleTask,
  onTaskEdit,
  isLastColumn,
}) => {
  const api = useTalentApi()

  const [taskList, setTaskList] = useState(tasks)

  const [taskToFullfil, setTaskToFullfil] = useState()

  const handleToogleDone = async (task) => {
    // if done then mark as undone
    if (task.isDone) {
      await api.patch(`tasks/${task._id}`, {
        isDone: false,
      })

      await api.delete(`tasks/${task._id}/doneNote`)

      const newTaskList = taskList.map((t) => {
        if (t._id === task._id) {
          t.isDone = false
          delete t.doneNote
        }

        return t
      })

      setTaskList(newTaskList)

      return
    }

    // Mark as done
    if (task.requireDoneNote && !task.doneNote) {
      setTaskToFullfil(task)
      return
    }

    await api.patch(`tasks/${task._id}`, {
      isDone: true,
      doneNote: task.doneNote,
    })

    const newTaskList = taskList.map((t) => {
      if (t._id === task._id) {
        t.isDone = true
        t.doneNote = task.doneNote
      }

      return t
    })

    setTaskToFullfil(null)
    setTaskList(newTaskList)

    await timeout(500)

    onScheduleTask()
  }

  return (
    <React.Fragment>
      <Dropdown
        pointing={isLastColumn ? 'right' : 'left'}
        style={style}
        trigger={<TasksIcon tasks={taskList} />}
        icon={null}
      >
        <Dropdown.Menu
          style={{
            top: -9,
            left: isLastColumn ? null : 9,
            right: isLastColumn ? 13 : 0,
          }}
        >
          {taskList.length === 0 && (
            <Dropdown.Item disabled>No tasks scheduled!</Dropdown.Item>
          )}

          {taskList
            .sort((a, b) => a.dueDate - b.dueDate)
            .map((t) => (
              <TaskItem
                key={t._id}
                task={t}
                onToggleDone={() => handleToogleDone(t)}
                onTaskEdit={() => onTaskEdit(t)}
              />
            ))}

          <DropdownDivider />

          <Dropdown.Item onClick={onScheduleTask}>
            <Icon name="calendar plus outline" /> Schedule a task
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <CompleteTaskModal
        key={taskToFullfil}
        taskToFullfil={taskToFullfil}
        onMarkAsDone={handleToogleDone}
        onCancel={() => setTaskToFullfil(null)}
      />
    </React.Fragment>
  )
}

const TaskItem = ({ task, onToggleDone, onTaskEdit }) => {
  const taskDueDate = new Date(task.dueDate)

  let color = '#858585'
  let message = `Due on ${taskDueDate.toLocaleDateString('en-US')}`

  if (isToday(taskDueDate)) {
    color = 'var(--primary)'
    message = 'Today!'
  } else if (isBeforeToday(taskDueDate)) {
    color = 'var(--red)'
    message = `Overdue (${taskDueDate.toLocaleDateString('en-US')})`
  }

  return (
    <Dropdown.Item onClick={(e) => e.stopPropagation()}>
      <TaskItemContainer>
        <CheckButton value={task.isDone} onMouseUp={onToggleDone} />

        <div style={{ marginLeft: 10 }} onClick={onTaskEdit}>
          <TaskTitle
            style={{ textDecoration: task.isDone ? 'line-through' : 'none' }}
          >
            {task.subject}
          </TaskTitle>

          {task.details && (
            <TaskDetail dangerouslySetInnerHTML={{ __html: task.details }} />
          )}

          <div>
            <span style={{ color }}>{message}</span> -{' '}
            <UserName both={true} id={task.assignedToId} short />
          </div>
        </div>
      </TaskItemContainer>
    </Dropdown.Item>
  )
}

const TasksIcon = ({ tasks }) => {
  const unDoneTasks = tasks.filter((x) => !x.isDone)

  if (unDoneTasks.length === 0) {
    return <TasksIco style={{ color: '#f3df3a' }} name="exclamation circle" />
  }

  const anyOverdue = unDoneTasks.some(
    (x) => getDiffInDays(new Date(), x.dueDate) < 0
  )

  if (anyOverdue) {
    return (
      <TasksIco style={{ color: 'var(--red)' }} name="chevron circle right" />
    )
  }

  const anyToday = unDoneTasks.some(
    (x) => getDiffInDays(new Date(), x.dueDate) === 0
  )

  if (anyToday) {
    return (
      <TasksIco
        style={{ color: 'var(--primary)' }}
        name="chevron circle right"
      />
    )
  }

  return <TasksIco name="chevron circle right" />
}

export default TasksMenu
