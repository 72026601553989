import * as React from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import styled from 'styled-components'

const D = styled.div`
  div.field {
    width: 100% !important;

    div.ui.input {
      width: 100% !important;
    }
  }
`

const DateSelect = (props) => {
  const handleChange = (e, data) => {
    if (!data.value) {
      props.onChange && props.onChange(e, data)
      return
    }

    props.onChange &&
      props.onChange(e, {
        name: props.name,
        value: new Date(data.value).getTime(),
      })
  }

  return (
    <D>
      <form autoComplete="off">
        <SemanticDatepicker {...props} onChange={handleChange} />
      </form>
    </D>
  )
}

export default DateSelect
