import React, { useState } from 'react'
import { Button, Dropdown, DropdownItem } from 'semantic-ui-react'
import styled from 'styled-components'

import AddTaskModal from '../modals/addTaskModal'
import AddLogModal from '../modals/addLogModal'

const Container = styled.div`
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`

const B = styled.div`
  text-align: center;
  margin-right: 20px;
  min-width: 55px;

  &:last-child {
    margin-right: 0px;
  }
`

const Label = styled.div`
  margin-top: 5px;
`

const CompanyActions = ({ company, onSuccess }) => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)
  const [showAddLogModal, setShowAddLogModal] = useState(false)

  // const handleRemove = async () => {
  //   await api.delete(`contacts/${contact._id}`);

  //   setShowConfirmRemove(false);

  //   history.push(`/contacts`);
  // };

  const handleTaskCreated = () => {
    setShowAddTaskModal(false)
    onSuccess && onSuccess()
  }

  const handleLogCreated = () => {
    setShowAddLogModal(false)
    onSuccess && onSuccess()
  }

  return (
    <React.Fragment>
      <Container>
        <B>
          <Button
            color="red"
            circular
            icon="trash alternate outline"
            // onClick={() => setShowConfirmRemove(true)}
          ></Button>
          <Label>Remove</Label>
        </B>

        <B>
          <Button
            basic
            circular
            icon="mail alternate"
            // onClick={() => (document.location.href = `mailto:${contact.email}`)}
          ></Button>
          <Label>Mail</Label>
        </B>

        <B>
          <Button
            basic
            circular
            icon="share"
            onClick={() => alert('Not ready yet. Sorry.')}
          ></Button>
          <Label>Share</Label>
        </B>

        <B>
          <Dropdown
            pointing="left"
            icon={null}
            trigger={
              <Button
                color="black"
                circular
                icon="ellipsis horizontal"
              ></Button>
            }
          >
            <Dropdown.Menu>
              <DropdownItem>Log Something</DropdownItem>
              <DropdownItem>Schedule Task</DropdownItem>
            </Dropdown.Menu>
          </Dropdown>

          <Label>More</Label>
        </B>
      </Container>

      {/* <ConfirmRemoveModal
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemove(false)}
        show={showConfirmRemove}
        validationValue={contact.name}
        header="Hey...Removing this contact?"
        content={
          <React.Fragment>
            <p>
              You are about to remove{" "}
              <b className="highlight">{contact.name}</b>.{" "}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter contact's name to confirm.</b>
            </p>
          </React.Fragment>
        }
      /> */}

      <AddTaskModal
        show={showAddTaskModal}
        relatedId={company._id}
        relatedCollection="companies"
        onCancel={() => setShowAddTaskModal(false)}
        onCreated={handleTaskCreated}
      />

      <AddLogModal
        show={showAddLogModal}
        relatedId={company._id}
        relatedCollection="companies"
        onCancel={() => setShowAddLogModal(false)}
        onCreated={handleLogCreated}
      />
    </React.Fragment>
  )
}

export default CompanyActions
