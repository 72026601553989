import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  // ButtonGroup,
  Dropdown,
  // DropdownItem,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'
import DataSelect from '../form/dataSelect'
import FormField from '../form/formField'
// import TextArea from "../form/textArea";
import { useUser } from '../../pages/session/hooks'
import UserSelect from '../form/userSelect'
import DateSelect from '../form/dateSelect'
import ShowIf from '../showIf'
import Check from '../form/check'
import ContentEditable from 'react-contenteditable'

const Title = styled.h2`
  font-weight: bold !important;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Column = styled.div`
  /* margin-right: 20px;
  margin-right: 0px; */
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
`

const Editable = styled(ContentEditable)`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  height: 100px;
  max-height: 100px;
  overflow: auto;

  &:focus {
    outline: none;

    border: solid 1px var(--primary);
    /* background-color: var(--primary-light); */
    box-shadow: var(--active-shadow);
  }
`

const AddTaskModal = ({
  show,
  onCancel,
  onCreated,
  taskToEdit,
  onEdited,
  relatedCollection,
  relatedId,
}) => {
  const editor = useRef()

  const user = useUser()
  const api = useTalentApi()

  const [task, setTask] = useState({ relatedCollection: 'nothing' })
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [editMode, setEditMode] = useState(false)

  const [isSaving] = useState(false)

  useEffect(() => {
    if (!show) {
      return
    }

    setErrors([])

    if (!taskToEdit) {
      setEditMode(false)

      const newTask = {
        dueDate: new Date(new Date().toDateString()).getTime(),
        assignedToId: user._id,
        relatedId,
        relatedCollection,
      }

      setTask(newTask)
      return
    }

    setTask({ ...taskToEdit })
    setEditMode(true)
  }, [show, relatedCollection, relatedId, taskToEdit, user._id])

  const handleQuickActionClick = (e) => {
    setTask({ ...task, subject: e.target.innerHTML.replace('<br>', ' ') })

    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }

  if (!show) {
    return null
  }

  const handleOnChange = (e, target) => {
    const newTask = { ...task }

    if (target.name === 'dueDate') {
      newTask[target.name] = new Date(
        new Date(target.value).toLocaleDateString('en-US')
      ).getTime()
    } else {
      newTask[target.name] = target.value || target.checked
    }

    if (target.value !== '') {
      setErrors(errors.filter((x) => x !== target.name))
    }

    setTask(newTask)
  }

  const handleCreate = async () => {
    setIsLoading(true)

    try {
      const { data } = await api.post(`tasks`, task)
      onCreated && onCreated(data)
    } catch ({ response }) {
      setErrors(response.data.fields)
    }

    setIsLoading(false)
  }

  const handleEdit = async () => {
    setIsLoading(true)

    try {
      const updated = { ...task }
      delete updated._id

      await api.patch(`tasks/${task._id}`, updated)
      onEdited && onEdited(task)
    } catch ({ response }) {
      setErrors(response.data.fields)
    }

    setIsLoading(false)
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="large"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        {!editMode && <Title>Whats the next step?</Title>}
        {editMode && <Title>Edit Task</Title>}

        <div>
          <div style={{ marginBottom: 20 }}>
            <p>Quick Actions:</p>
            <Button.Group basic style={{ width: `100%` }} disabled={isSaving}>
              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Send
                <br />
                Feedback
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Reschedule
                <br />
                Interview
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Send
                <br />
                Challenge
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Prepare to
                <br />
                Send
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Send to
                <br />
                the Customer
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Schedule
                <br />
                with Customer
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Decide
                <br />
                what to do
              </Button>

              <Button disabled={isSaving} onClick={handleQuickActionClick}>
                Follow
                <br />
                up
              </Button>
            </Button.Group>
          </div>

          <FormField
            label="Subject *"
            help="Specify an action, something to do."
            error={errors.indexOf('subject') !== -1}
          >
            <Input
              fluid={true}
              error={errors.indexOf('subject') !== -1}
              name="subject"
              autoFocus
              onChange={handleOnChange}
              placeholder="Task Subject..."
              value={task.subject}
            />
          </FormField>

          <FormField label="Details *" error={errors.indexOf('details') !== -1}>
            <Editable
              name="details"
              onChange={(e) =>
                setTask({ ...task, details: e.target.value.trim() })
              }
              placeholder="Task details..."
              html={task.details || ''}
              ref={editor}
            />
          </FormField>

          <ShowIf if={!relatedId}>
            <p>
              Related To{' '}
              <Dropdown
                value={task.relatedCollection}
                inline
                options={[
                  { key: 'nothing', value: 'nothing', text: 'Nothing' },
                  {
                    key: 'candidates',
                    value: 'candidates',
                    text: 'a Candidate',
                  },
                  { key: 'contacts', value: 'contacts', text: 'a Contact' },
                  { key: 'companies', value: 'companies', text: 'a Company' },
                  {
                    key: 'positions',
                    value: 'positions',
                    text: 'a Position',
                  },
                ]}
                onChange={(e, t) =>
                  setTask({ ...task, relatedCollection: t.value })
                }
              ></Dropdown>
            </p>

            <FormField>
              {task.relatedCollection === 'candidates' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Candidate..."
                  url="candidates"
                  fluid={true}
                  onChange={handleOnChange}
                  render={(x) => `${x.name} (${x.email})`}
                  value={task.relatedId}
                />
              )}

              {task.relatedCollection === 'contacts' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Contact..."
                  url="contacts"
                  fluid={true}
                  onChange={handleOnChange}
                  render={(x) => `${x.name} (${x.email})`}
                  value={task.relatedId}
                />
              )}

              {task.relatedCollection === 'companies' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Company..."
                  url="companies"
                  fluid={true}
                  onChange={handleOnChange}
                  render={(x) => x.name}
                  value={task.relatedId}
                />
              )}

              {task.relatedCollection === 'positions' && (
                <DataSelect
                  name="relatedId"
                  search
                  placeholder="Position..."
                  url="positions"
                  fluid={true}
                  onChange={handleOnChange}
                  render={(x) => `${x.number} - ${x.title}`}
                  value={task.relatedId}
                  filter={{ status: 'open' }}
                />
              )}
            </FormField>
          </ShowIf>

          <Container>
            <Column>
              <FormField
                label="Assigned To *"
                error={errors.indexOf('assignedToId') !== -1}
              >
                <UserSelect
                  name="assignedToId"
                  search
                  placeholder="Assigned To..."
                  fluid={true}
                  onChange={handleOnChange}
                  render={(x) => x.name}
                  value={task.assignedToId}
                  error={errors.indexOf('assignedToId') !== -1}
                />
              </FormField>
            </Column>

            <Column>
              <FormField
                label="Due Date *"
                error={errors.indexOf('dueDate') !== -1}
              >
                <DateSelect
                  error={errors.indexOf('dueDate') !== -1}
                  name="dueDate"
                  pointing="top left"
                  fluid
                  onChange={handleOnChange}
                  value={task.dueDate && new Date(task.dueDate)}
                />
              </FormField>
            </Column>

            <Column>
              <Check
                text="Requires fulfillment note"
                value={task.requireDoneNote}
                onChange={() =>
                  setTask({ ...task, requireDoneNote: !task.requireDoneNote })
                }
              />
            </Column>
          </Container>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel}>
          Nervemind...
        </Button>
        {!editMode && (
          <Button color="black" onClick={handleCreate} loading={isLoading}>
            <Icon name="save" /> Let&apos;s do it!
          </Button>
        )}
        {editMode && (
          <Button color="black" onClick={handleEdit} loading={isLoading}>
            <Icon name="save" /> Save Changes!
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default AddTaskModal
