import React, { useState, useEffect } from 'react'
import { Select } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'

const DataSelect = ({
  name,
  value,
  onChange,
  disabled,
  placeholder,
  multiple,
  url,
  search,
  style,
  render,
  orderBy,
  open,
  fluid,
  clearable = true,
  getValue,
  filter,
  allowAdditions,
}) => {
  const api = useTalentApi()

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setIsLoading(true)
    api
      .get(url, { params: filter })
      .then(({ data }) => {
        if (orderBy) {
          setOptions(data.sort((a, b) => orderBy(a) - orderBy(b)))
        } else {
          setOptions(data)
        }
      })
      .finally(() => setIsLoading(false))
  }, [api, orderBy, filter, url])

  return (
    <Select
      style={style}
      search={search}
      multiple={multiple}
      name={name}
      placeholder={placeholder}
      options={options.map((x) => ({
        key: x._id,
        value: getValue ? getValue(x) : x._id,
        text: render ? render(x) : x.text,
      }))}
      disabled={disabled}
      onChange={onChange}
      loading={isLoading}
      value={value}
      clearable={clearable}
      open={open}
      fluid={fluid}
      allowAdditions={allowAdditions}
    ></Select>
  )
}

export default DataSelect
