import React, { useEffect, useState } from 'react'
import { Select } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'

const PositionSelect = ({
  search,
  fluid,
  multiple,
  name,
  placeholder,
  disabled,
  onChange,
  filter,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState([])

  const api = useTalentApi()

  useEffect(() => {
    setIsLoading(true)

    api
      .get(`positions`, { params: filter })
      .then(({ data: positions }) => {
        const companyIds = [
          ...new Set(positions.map((x) => x.companyId).filter((x) => x)),
        ]
        const hiringManagersId = [
          ...new Set(positions.map((x) => x.hiringManagerId).filter((x) => x)),
        ]
        return api
          .get(`companies/in`, { params: { _id: companyIds } })
          .then(({ data: companies }) =>
            api
              .get(`contacts/in`, { params: { _id: hiringManagersId } })
              .then(({ data: contacts }) => {
                const opts = positions.map((p) => {
                  const company = companies.find(
                    (x) => x._id === p.companyId
                  ) || { name: 'No Company' }
                  const hm = contacts.find(
                    (x) => x._id === p.hiringManagerId
                  ) || { name: 'No HM.' }
                  let text = `${p.number} - ${company.name} - ${p.title} (${hm.name})`
                  if (p.status === 'closed') {
                    text = `${text} (Closed)`
                  }
                  return { value: p._id, key: p._id, text }
                })
                setOptions(opts)
              })
          )
      })
      .finally(() => setIsLoading(false))
  }, [filter, api])

  return (
    <Select
      search={search}
      multiple={multiple}
      name={name}
      placeholder={placeholder || 'Position...'}
      options={options}
      disabled={disabled}
      onChange={onChange}
      loading={isLoading}
      fluid={fluid}
    ></Select>
  )
}

export default PositionSelect
