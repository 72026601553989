import React, { useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import PageHeader from '../../components/pageHeader/pageHeader'
import TaskRow from './taskRow'
import { useUser } from '../../pages/session/hooks'
import { useTalentApi } from '../../store/mainContext'
import PageLoader from '../../components/pageLoader'
import DataTable from '../../components/dataTable/dataTable'

import AddTaskModal from '../../components/modals/addTaskModal'
import CompleteTaskModal from '../../components/modals/completeTaskModal'
import UserSelect from '../../components/form/userSelect'
import { FlexDiv } from '../../components/html'
import UserName from '../../components/remoteValues/userName'
import RequirePowers from '../../components/requirePowers'
import ShowIf from '../../components/showIf'
import { isBeforeToday, isToday, isAfterToday, timeout } from '../../utils'

import POWERS from '../../powers'

const PageContent = styled.div`
  margin: 20px 40px;
`

const Tasks = () => {
  document.title = 'Tasks'

  const api = useTalentApi()

  const user = useUser()

  const [selectedUserId, setSelectedUserId] = useState(user._id)

  const [candidates, setCandidates] = useState([])
  const [contacts, setContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [positions, setPositions] = useState([])

  const [tasks, setTasks] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [taskToEdit, setTaskToEdit] = useState()

  const [taskToFullfil, setTaskToFullfil] = useState()

  const sortTasks = (list) =>
    list.sort((a, b) => a.dueDate - b.dueDate || a.createdOn - b.createdOn)

  const filterTasks = (list) => list.filter((x) => !x.isDone)

  const refreshTasks = React.useCallback(async () => {
    const tasksQuery = {
      assignedToId: selectedUserId,
      isDone: { $in: [null, false] },
    }

    const { data } = await api.get('tasks/pro', { params: tasksQuery })

    const { data: pos } = await api.get('positions/in', {
      params: {
        _id: data
          .filter((x) => x.relatedId && x.relatedCollection === 'positions')
          .map((x) => x.relatedId),
      },
    })

    const { data: cand } = await api.get('candidates/in', {
      params: {
        _id: data
          .filter((x) => x.relatedId && x.relatedCollection === 'candidates')
          .map((x) => x.relatedId),
      },
    })

    const { data: cont } = await api.get('contacts/in', {
      params: {
        _id: data
          .filter((x) => x.relatedId && x.relatedCollection === 'contacts')
          .map((x) => x.relatedId),
      },
    })

    const { data: comp } = await api.get('companies/in', {
      params: {
        _id: data
          .filter((x) => x.relatedId && x.relatedCollection === 'companies')
          .map((x) => x.relatedId),
      },
    })

    setCandidates(cand)
    setContacts(cont)
    setCompanies(comp)
    setPositions(pos)

    setTasks(sortTasks(filterTasks(data)))
    setIsLoading(false)
  }, [api, selectedUserId])

  useEffect(() => {
    refreshTasks()
  }, [selectedUserId, refreshTasks])

  const handleCreated = async (newTask) => {
    setShowAddModal(false)
    await refreshTasks()
  }

  const handleEdited = async (updatedTask) => {
    setShowAddModal(false)
    await refreshTasks()
  }

  const handleEdit = (task) => {
    setTaskToEdit(task)
    setShowAddModal(true)
  }

  const handleAdd = () => {
    setTaskToEdit(null)
    setShowAddModal(true)
  }

  const handleDone = async (task) => {
    if (task.requireDoneNote && !task.doneNote) {
      setTaskToFullfil(task)
      return
    }

    setTaskToFullfil(null)

    task.isDone = true

    const data = [...filterTasks(tasks).filter((x) => x._id !== task._id), task]

    setTasks(sortTasks(data))

    await api.patch(`tasks/${task._id}`, {
      isDone: task.isDone,
      doneNote: task.doneNote,
    })

    await timeout(1000)

    await refreshTasks()
  }

  const handleRemove = async (task) => {
    api.delete(`tasks/${task._id}`) // fire and forget.
    setTasks(tasks.filter((t) => t._id !== task._id))
  }

  if (isLoading) {
    return <PageLoader />
  }

  const hasDueTasks = tasks.filter((x) => isBeforeToday(x.dueDate)).length > 0

  const todayAndOverdueTasks = tasks
    .filter((x) => isBeforeToday(x.dueDate) || isToday(x.dueDate))
    .sort((a, b) => a.dueDate - b.dueDate)
  const futureTasks = tasks
    .filter((x) => isAfterToday(x.dueDate))
    .sort((a, b) => a.dueDate - b.dueDate)

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Templates', link: '/templates' },
        ]}
        title={`Tasks`}
        sub="One list to do it all."
        actions={
          <FlexDiv>
            <RequirePowers powers={[POWERS.master]}>
              <UserSelect
                clerable={false}
                render={(x) => x.name}
                value={selectedUserId}
                onChange={(e, target) => setSelectedUserId(target.value)}
              />
            </RequirePowers>

            <Button style={{ marginLeft: 10 }} basic onClick={handleAdd}>
              <Icon name="add" /> Add Task
            </Button>
          </FlexDiv>
        }
      />

      <PageContent>
        <h2>
          Today &amp; Due Tasks (
          {tasks.filter((x) => x.dueDate < Date.now()).length})
        </h2>

        <ShowIf if={todayAndOverdueTasks.length > 0}>
          {hasDueTasks && (
            <p>
              Hey... came on! catch up with your{' '}
              <b style={{ color: 'red' }}>due tasks!</b>
            </p>
          )}
          {!hasDueTasks && (
            <p>
              <b style={{ color: 'var(--primary)' }}>Awesome!</b> you are up to
              date! don&apos;t let your tasks to expire!
            </p>
          )}

          <DataTable>
            <thead>
              <tr>
                <DataTable.Th width={85} align="center">
                  Done
                </DataTable.Th>
                <DataTable.Th width={200}>Subject</DataTable.Th>
                <DataTable.Th>Details</DataTable.Th>
                <DataTable.Th width={180}>Related To</DataTable.Th>
                <DataTable.Th width={180} align="center">
                  Due Date
                </DataTable.Th>
                <DataTable.Th width={200} align="left">
                  Assigned By
                </DataTable.Th>
                <DataTable.Th width={250}></DataTable.Th>
              </tr>
            </thead>

            <tbody>
              {todayAndOverdueTasks.map((task) => (
                <TaskRow
                  key={task._id}
                  task={task}
                  candidate={candidates.find((x) => x._id === task.relatedId)}
                  contact={contacts.find((x) => x._id === task.relatedId)}
                  company={companies.find((x) => x._id === task.relatedId)}
                  position={positions.find((x) => x._id === task.relatedId)}
                  onEdit={handleEdit}
                  onDone={handleDone}
                  onRemove={handleRemove}
                />
              ))}
            </tbody>
          </DataTable>
        </ShowIf>

        <ShowIf if={todayAndOverdueTasks.length === 0}>
          <p>No tasks yet! :)</p>
        </ShowIf>

        <br />

        <h2>Future Tasks</h2>

        <ShowIf if={futureTasks.length > 0}>
          <p>
            <UserName key={selectedUserId} id={selectedUserId} short />
            &apos;s future problems, don&apos;t worry about them now.
          </p>

          <DataTable>
            <thead>
              <tr>
                <DataTable.Th width={85} align="center">
                  Done
                </DataTable.Th>
                <DataTable.Th width={200}>Subject</DataTable.Th>
                <DataTable.Th width={300}>Details</DataTable.Th>
                <DataTable.Th width={180}>Related To</DataTable.Th>
                <DataTable.Th width={180} align="center">
                  Due Date
                </DataTable.Th>
                <DataTable.Th width={200} align="left">
                  Assigned By
                </DataTable.Th>
                <DataTable.Th></DataTable.Th>
              </tr>
            </thead>

            <tbody>
              {futureTasks.map((task) => (
                <TaskRow
                  key={task._id}
                  task={task}
                  candidate={candidates.find((x) => x._id === task.relatedId)}
                  contact={contacts.find((x) => x._id === task.relatedId)}
                  company={companies.find((x) => x._id === task.relatedId)}
                  position={positions.find((x) => x._id === task.relatedId)}
                  onEdit={handleEdit}
                  onDone={handleDone}
                  onRemove={handleRemove}
                />
              ))}
            </tbody>
          </DataTable>
        </ShowIf>

        <ShowIf if={futureTasks.length === 0}>
          <p>No tasks yet! :)</p>
        </ShowIf>

        <AddTaskModal
          key={showAddModal}
          show={showAddModal}
          onCancel={() => setShowAddModal(false)}
          onCreated={handleCreated}
          onEdited={handleEdited}
          taskToEdit={taskToEdit}
        />

        <CompleteTaskModal
          key={taskToFullfil}
          taskToFullfil={taskToFullfil}
          onCancel={() => setTaskToFullfil(null)}
          onMarkAsDone={handleDone}
        />
      </PageContent>
    </React.Fragment>
  )
}

export default Tasks
