import React, { useState } from 'react'
import {
  Button,
  Header,
  // , Icon
  Input,
  Modal,
} from 'semantic-ui-react'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  width: 100%;
  font-size: 17px !important;
  /* padding: 0px!important; */
`

const ConfirmRemoveModal = ({
  show,
  validationValue,
  content,
  header,
  onCancel,
  onConfirm,
}) => {
  const [, setValue] = useState()
  const [enableRemove, setEnableRemove] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (!show) {
    return null
  }

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirm()
  }

  const handleOnChange = (e) => {
    setValue(e.target.value)
    setEnableRemove(
      e.target.value.toLowerCase() === validationValue.toLowerCase()
    )
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="small"
      closeOnDimmerClick={true}
      // dimmer="blurring"
      // inverted
    >
      <Header>{header}</Header>
      <Modal.Content>
        {content}

        <StyledInput
          autoFocus
          onChange={handleOnChange}
          placeholder={'Enter value to confirm...'}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel}>
          Ugh...No no no, I&apos;m sorry...
        </Button>
        <Button
          color="red"
          disabled={!enableRemove}
          onClick={handleConfirm}
          isLoading={isLoading}
        >
          Sure. Remove.{' '}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmRemoveModal
