import * as React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Btn = styled(Button)`
  padding: 2px !important;
  font-size: 11px !important;
  width: 22px !important;
  height: 22px !important;
  border-radius: 4px !important;
`

const Label = styled.div`
  font-size: 15px;
  margin-left: 5px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Check = ({ value, onChange, text }) => {
  if (!value) {
    return (
      <Container onClick={onChange}>
        <Btn basic icon size="mini">
          <Icon name="" />
        </Btn>{' '}
        <Label>{text}</Label>
      </Container>
    )
  }

  return (
    <Container onClick={onChange}>
      <Btn color="black" icon size="mini">
        <Icon name="check" />
      </Btn>{' '}
      <Label>{text}</Label>
    </Container>
  )
}

export default Check
