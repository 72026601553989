import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'
// import DataSelect from '../form/dataSelect';
import AddCompanyModal from '../modals/addCompanyModal'

const CompanySelect = ({
  allowAdditions,
  onChange,
  name,
  fluid,
  error,
  placeholder,
  value,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [options, setOptions] = useState([])

  const api = useTalentApi()

  useEffect(() => {
    api
      .get(`companies`)
      .then(({ data }) =>
        setOptions(
          data.map((c) => ({
            text: c.name,
            value: c._id,
            key: c._id,
          }))
        )
      )
      .finally(() => setIsLoading(false))
  }, [api])

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const handleAddCompany = (e, payload) => {
    setSelectedValue(payload.value)
    setShowAddModal(true)
  }

  const handleCancel = () => {
    setSelectedValue(null)
    setShowAddModal(false)
    onChange && onChange(null, { name, value: null })
  }

  const handleCompanyCreated = (company) => {
    const selectedOption = {
      text: company.name,
      value: company._id,
      key: company._id,
    }

    setOptions([...options, selectedOption])
    setSelectedValue(selectedOption.value)

    setShowAddModal(false)
    console.log('selected', selectedOption)
    onChange && onChange(null, { name, value: selectedOption.value })
  }

  const handleOnChange = (e, t) => {
    setSelectedValue(t.value)

    onChange && onChange(e, t)
  }

  return (
    <React.Fragment>
      <Dropdown
        key={selectedValue}
        name={name}
        // onSearchChange={handleSearchChange}
        onChange={handleOnChange}
        placeholder={placeholder || 'Company...'}
        search
        allowAdditions={allowAdditions}
        fluid={fluid || true}
        selection
        options={options}
        loading={isLoading}
        onAddItem={handleAddCompany}
        value={selectedValue}
        error={error}
        clearable={true}
      />

      <AddCompanyModal
        open={showAddModal}
        defaults={{ name: selectedValue }}
        onCreated={handleCompanyCreated}
        onCancel={handleCancel}
      />
    </React.Fragment>
  )
}

export default CompanySelect
