import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTalentApi } from '../../store/mainContext'

const EntityLink = ({ entityId, entity, collection, defaultText }) => {
  const [val, setVal] = useState()

  const api = useTalentApi()

  useEffect(() => {
    if (!entityId) return
    api.get(`${collection}/${entityId}`).then(({ data }) => setVal(data))
  }, [entityId, api, collection])

  useEffect(() => {
    setVal(entity)
  }, [entity])

  if (!val) {
    return defaultText || null
  }

  return (
    <a rel="noreferrer" href={`/${collection}/${val._id}`} target="_blank">
      {val.name || val.title}
    </a>
  )
}

export default EntityLink
