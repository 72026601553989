import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ContentEditable from 'react-contenteditable'

import { Button, Icon, Input } from 'semantic-ui-react'

import FormField from '../../form/formField'

import { useTalentApi } from '../../../store/mainContext'
// import DataSelect from "../../form/dataSelect";
import UserSelect from '../../form/userSelect'
import DateSelect from '../../form/dateSelect'
import Check from '../../form/check'

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
`

const Container = styled.div`
  margin-top: 20px;
`

// const QuickTasksRow = styled.div`
//   margin-top: 5px;
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   vertical-align: middle;
// `

const ColumnContainer = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Column = styled.div`
  /* margin-right: 20px;
  margin-right: 0px; */
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
`

const Editable = styled(ContentEditable)`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 16px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  min-height: 100px;
  /* max-height: 100px; */
  overflow: hidd;
  margin-top: 10px;

  &:focus {
    outline: none;
    /* min-height: 200px; */
    border: solid 1px var(--primary);
    /* background-color: var(--primary-light); */
    box-shadow: var(--active-shadow);
  }
`

const TaskCreator = ({ relatedCollection, relatedId, onSuccess }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])

  const defaultValue = 'Say something smart...'

  const [task, setTask] = useState({})

  const [taskDetails, setTaskDetails] = useState(defaultValue)

  const api = useTalentApi()

  const commonTaskSubjects = [
    'Schedule Interview',
    'Interview',
    'Reschedule Interview',
    'Send Feedback',
    'Send Challenge',
    'Check challenge',
    'Prepare to send',
    'Send to the customer',
    'Schedule with Customer',
    'Customer Interview',
    'Decide what to do',
    'Follow up',
  ]

  useEffect(() => {
    const localStorageValue = window.localStorage.getItem(
      `task-editor.${relatedId}.details`
    )
    setTaskDetails(localStorageValue || defaultValue)
  }, [relatedId])

  const handleOnChange = (e, target) => {
    const newTask = { ...task }

    newTask[target.name] = target.value || target.checked

    setTask(newTask)
  }

  const handleFocus = () => {
    if (taskDetails === defaultValue) {
      setTaskDetails('')
    }
  }

  const handleOnBlur = (e) => {
    if (e.target.innerHTML === '') {
      setTaskDetails(defaultValue)
    }
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      const { data } = await api.post('tasks', {
        ...task,
        details: taskDetails !== defaultValue ? taskDetails : null,
        relatedId,
        relatedCollection,
      })

      onSuccess && onSuccess(data)

      clearForm()
    } catch ({ response }) {
      setErrors(response.data.fields)
    }

    setIsSaving(false)
  }

  const clearForm = () => {
    setErrors([])
    setTask({
      assignedToId: null,
      subject: '',
      dueDate: null,
      requireDoneNote: null,
    })
    setTaskDetails(defaultValue)
    window.localStorage.removeItem(`task-editor.${relatedId}.details`)
  }

  return (
    <Container>
      <Input
        fluid={true}
        error={errors.indexOf('subject') !== -1}
        name="subject"
        autoFocus
        onChange={handleOnChange}
        placeholder="What is the next step?..."
        value={task.subject}
        list="commonTasks"
        icon={false}
        style={{ fontSize: '16px' }}
        disabled={isSaving}
      />
      <datalist id="commonTasks">
        {commonTaskSubjects.map((x) => (
          <option key={x}>{x}</option>
        ))}
      </datalist>

      <Editable
        name="details"
        onChange={(e) => {
          setTaskDetails(e.target.value.trim())
          window.localStorage.setItem(
            `task-editor.${relatedId}.details`,
            e.target.value.trim()
          )
        }}
        placeholder="Task details..."
        html={taskDetails || ''}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
        style={{ color: taskDetails === defaultValue && '#bbb' }}
        disabled={isSaving}
      />

      <ColumnContainer>
        <Column>
          <FormField
            label="Assigned To *"
            error={errors.indexOf('assignedToId') !== -1}
          >
            <UserSelect
              name="assignedToId"
              search
              placeholder="Assigned To..."
              fluid={true}
              onChange={handleOnChange}
              render={(x) => x.name}
              value={task.assignedToId}
              error={errors.indexOf('assignedToId') !== -1}
              disabled={isSaving}
            />
          </FormField>
        </Column>

        <Column>
          <FormField
            label="Due Date *"
            error={errors.indexOf('dueDate') !== -1}
          >
            <DateSelect
              error={errors.indexOf('dueDate') !== -1}
              name="dueDate"
              pointing="top left"
              fluid
              onChange={handleOnChange}
              value={task.dueDate && new Date(task.dueDate)}
              disabled={isSaving}
            />
          </FormField>
        </Column>

        <Column>
          <Check
            text="Requires fulfillment note."
            value={task.requireDoneNote}
            onChange={() =>
              setTask({ ...task, requireDoneNote: !task.requireDoneNote })
            }
            disabled={isSaving}
          />
        </Column>
      </ColumnContainer>

      <ButtonBar>
        <Button basic disabled={isSaving} onClick={clearForm}>
          Cancel
        </Button>
        <Button color="black" onClick={handleSave} loading={isSaving}>
          <Icon name="save"></Icon>Save
        </Button>
      </ButtonBar>
    </Container>
  )
}

export default TaskCreator
