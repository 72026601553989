import React, { useEffect, useState } from 'react'
import { Button, Icon, Input, Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'
import FormField from '../form/formField'

const Title = styled.h2`
  font-weight: bold !important;
`

const AddAccountModal = ({ show, candidate, onCancel, onSuccess }) => {
  const api = useTalentApi()

  const [isSaving, setSaving] = useState(false)
  const [errors] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const [emailToCreate, setEmailToCreate] = useState()

  useEffect(() => {
    if (!candidate) {
      return
    }

    const names = candidate.name.toLowerCase().split(' ')

    setEmailToCreate(`${names[0]}.${names[names.length - 1]}`)
  }, [candidate])

  const handleSend = async () => {
    setSaving(true)

    const info = {
      candidateId: candidate._id,
      fullname: candidate.name,
      personalEmail: candidate.email,
      emailToCreate: `${emailToCreate}@southteams.com`,
    }

    try {
      const { data } = await api.post('/user-accounts', info)

      onSuccess(data)
    } catch ({ response }) {
      setErrorMessage(response.data.message)
    } finally {
      setSaving(false)
    }
  }

  if (!show) {
    return null
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Title>New Account</Title>

        <p>
          You are about to create a new account for{' '}
          <b className="highlight">{candidate.name}</b>. This process will:
        </p>

        <p>
          1. Create the following <b>email account</b>:
        </p>

        <FormField>
          <Input
            name="emailToCreate"
            fluid
            placeholder="Email..."
            value={emailToCreate}
            onChange={(e) => setEmailToCreate(e.target.value)}
            label="@southteams.com"
            labelPosition="right"
            error={errors && errors.find((x) => x === 'emailToCreate')}
            disabled={isSaving}
          />
        </FormField>

        <p>
          2. Provide{' '}
          <b>
            <Icon name="slack" />
            Slack
          </b>{' '}
          access.
        </p>

        <p>
          3. Provide <b>Contractor Portal</b> access (me.southteams.com)
        </p>

        <p>
          4. Send the{' '}
          <b>
            <Icon name="envelope outline" />
            Getting Started
          </b>{' '}
          email to <b className="highlight">{candidate.email}</b> with access
          information.
        </p>

        <p>
          <b>Do you want to continue?</b>
        </p>

        {errorMessage && <p style={{ color: 'var(--red)' }}>{errorMessage}</p>}
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel} disabled={isSaving}>
          Nervemind...
        </Button>
        <Button
          color="black"
          onClick={handleSend}
          loading={isSaving}
          disabled={!emailToCreate}
        >
          <Icon name="flag checkered" /> Go Ahead!
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddAccountModal
