import * as React from 'react'
import { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'

const PositionLink = ({ positionId, style, showIcon = false }) => {
  const [position, setPosition] = useState()

  const api = useTalentApi()

  useEffect(() => {
    if (!positionId) return
    api
      .get(`positions/${positionId}`)
      .then(({ data }) =>
        data.companyId
          ? api.get(`companies/${data.companyId}`).then(({ data: comp }) => {
              data.company = comp
              return data
            })
          : data
      )
      .then((data) => setPosition(data))
      .catch((e) => console.warn(e))
  }, [positionId, api])

  if (!position) {
    return null
  }

  if (position.company) {
    return (
      <a
        rel="noreferrer"
        style={style}
        href={`/positions/${positionId}`}
        target="_blank"
      >
        {showIcon && <Icon name="file alternate outline" />} {position.number} -{' '}
        {position.company.name} {position.title}
      </a>
    )
  }

  return (
    <a
      rel="noreferrer"
      style={style}
      href={`/positions/${positionId}`}
      target="_blank"
    >
      {showIcon && <Icon name="file alternate outline" />} {position.number} -
      No Company - {position.title}
    </a>
  )
}

export default PositionLink
