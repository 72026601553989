import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Icon,
  //  Input,
  Modal,
} from 'semantic-ui-react'
import styled from 'styled-components'
// import { useTalentApi } from '../../store/mainContext'
// import DataSelect from "../form/dataSelect";
// import FormField from "../form/formField";
// import TextArea from "../form/textArea";
// import { useUser } from '../../pages/session/hooks'
// import UserSelect from "../form/userSelect";
// import DateSelect from "../form/dateSelect";
// import ShowIf from "../showIf";
// import Check from "../form/check";
import ContentEditable from 'react-contenteditable'

const Title = styled.h2`
  font-weight: bold !important;
`

// const Container = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
// `;

// const Column = styled.div`
//   /* margin-right: 20px;
//   margin-right: 0px; */
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   margin-right: 20px;
// `;

const Editable = styled(ContentEditable)`
  border: solid 1px var(--border-grey);
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  min-height: 150px;
  overflow: auto;
  max-height: 400px;

  &:focus {
    outline: none;

    border: solid 1px var(--primary);
    /* background-color: var(--primary-light); */
    box-shadow: var(--active-shadow);
  }
`

const CompleteTaskModal = ({ taskToFullfil, onCancel, onMarkAsDone }) => {
  const editor = useRef()

  // const user = useUser()
  // const api = useTalentApi()

  // const [task, setTask] = useState(taskToFullfil);
  const [
    isLoading,
    // , setIsLoading
  ] = useState(false)

  const defaultValue = 'Say something smart...'
  const [value, setValue] = useState(defaultValue)

  const [
    isSaving,
    // , setIsSaving
  ] = useState(false)

  useEffect(() => {
    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }, [taskToFullfil])

  const handleFocus = () => {
    if (value === defaultValue) {
      setValue('')
    }
  }

  const handleOnBlur = (e) => {
    if (e.target.innerHTML === '') {
      setValue(defaultValue)
    }
  }

  if (!taskToFullfil) {
    return null
  }

  return (
    <Modal
      onClose={onCancel}
      open={true}
      size="small"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Title>Fullfil Task</Title>

        <p>
          This task requires you to add a note before it can be marked as done.
          Please say something smart:
        </p>

        <p>
          <b>Task</b>
          <br />
          {taskToFullfil.subject}
        </p>

        {taskToFullfil.details && (
          <p>
            <b>Details</b>
            <div
              dangerouslySetInnerHTML={{ __html: taskToFullfil.details }}
            ></div>
          </p>
        )}

        <Editable
          html={value}
          disabled={isSaving}
          onChange={(e) => setValue(e.target.value.trim())}
          ref={editor}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          style={{ color: value === defaultValue && '#666' }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel}>
          Nervemind...
        </Button>
        <Button
          disabled={!value || value === defaultValue}
          color="black"
          onClick={() => onMarkAsDone({ ...taskToFullfil, doneNote: value })}
          loading={isLoading}
        >
          <Icon name="save" /> Mark as done!
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CompleteTaskModal
