import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Confirm, Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../store/mainContext'

const Container = styled.div`
  margin: 0px 30px 30px 30px;
  border: dotted 1px #e9c776;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff8db;
  color: #b58105;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: dotted 1px #e9c776;
  border-top: dotted 1px #e9c776;
  margin-bottom: -1px;

  padding: 5px;
`

const CandidateDuplicate = ({ candidate }) => {
  const [duplicates, setDuplicates] = useState([])
  const [candidateToMerge, setCandidateToMerge] = useState()
  const [isMerging, setIsMerging] = useState(false)

  const api = useTalentApi()

  useEffect(() => {
    const params = {
      name: candidate.name,
      email: candidate.email,
      linkedin: candidate.linkedin,
    }

    Object.keys(params).forEach((k) => {
      if (!params[k] || params[k] === '') delete params[k]
    })

    api
      .get(`candidates/any`, { params })
      .then(({ data }) =>
        setDuplicates(data.filter((x) => x._id !== candidate._id))
      )
  }, [candidate, api])

  const handleMerge = async () => {
    setIsMerging(true)
    const { data } = await api.post(`candidates/merge`, {
      ids: [candidateToMerge._id, candidate._id],
    })

    window.location = `/candidates/${data._id}`
  }

  if (duplicates.length === 0) {
    return null
  }

  return (
    <Container>
      <p>
        <b>This candidate could be duplicated:</b>
      </p>

      {duplicates.map((x) => (
        <Row key={x._id}>
          <Link to={`/candidates/${x._id}`} target="_blank">
            {x.name} ({new Date(x.createdOn).toLocaleDateString('en-US')})
          </Link>

          <Button
            size="mini"
            color="red"
            onClick={() => setCandidateToMerge(x)}
          >
            Merge
          </Button>
        </Row>
      ))}

      <Confirm
        key={candidateToMerge}
        open={candidateToMerge}
        header={'Merge Candidate'}
        content={
          <Modal.Content>
            <p>You are about to merge these two candidates:</p>

            <ul>
              <li>
                <b>
                  {candidate.name} ({candidate.email})
                </b>{' '}
                created on{' '}
                <b>
                  {new Date(candidate.createdOn).toLocaleDateString('en-US')}
                </b>
              </li>

              {candidateToMerge && (
                <li>
                  <b>
                    {candidateToMerge.name} ({candidateToMerge.email})
                  </b>{' '}
                  created on{' '}
                  <b>
                    {new Date(candidateToMerge.createdOn).toLocaleDateString(
                      'en-US'
                    )}
                  </b>
                </li>
              )}
            </ul>

            <p>This action will:</p>

            <ul style={{ listStyleType: `decimal` }}>
              <li>
                Move all tasks and events from the <b>newest</b> candidate to
                the <b>oldest</b> candidate
              </li>
              <li>
                <b style={{ color: 'var(--red)' }}>Remove</b> the <b>newest</b>{' '}
                candidate.
              </li>
            </ul>

            <p>
              <b>This action cannot be undone.</b> Do you want to continue?
            </p>
          </Modal.Content>
        }
        onCancel={() => setCandidateToMerge(null)}
        closeOnDimmerClick={true}
        size="tiny"
        onConfirm={handleMerge}
        confirmButton={
          <Button loading={isMerging} negative>
            Yeap... Lets merge them.
          </Button>
        }
        cancelButton={<Button basic>Ugh... no, no yet.</Button>}
      />
    </Container>
  )
}

export default CandidateDuplicate
