import React from 'react' // , { useState }
import { Button, Icon } from 'semantic-ui-react'
import DateFormat from '../dateFormat'
import { Section } from '../sidepanel/sidePanel'
import styled from 'styled-components'
import CompanyName from '../remoteValues/companyName'

const Container = styled.div`
  margin: 20px 50px 40px 50px;
`

const ContractsHistory = ({
  contracts,
  deals,
  onSelect,
  onGoBack,
  selectedDeal,
}) => (
  <Section
    icon="history"
    title="Contract History"
    menu={
      <Button size="mini" basic icon onClick={onGoBack}>
        <Icon name="caret left" /> Back
      </Button>
    }
  >
    <Container>
      {contracts.map((contract, index) => (
        <History
          key={contract._id}
          selectedDeal={selectedDeal}
          contract={contract}
          deals={deals.filter((x) => x.contractId === contract._id)}
          onSelect={onSelect}
        />
      ))}
    </Container>
  </Section>
)

const History = ({ selectedDeal, contract, deals, onSelect }) => (
  <React.Fragment>
    <h3>
      <Icon name="file alternate outline" />
      <CompanyName id={contract.customerId} /> (
      {new Date(contract.startingDate).toLocaleDateString()} -{' '}
      {contract.endDate
        ? new Date(contract.endDate).toLocaleDateString()
        : 'Present'}
      )
    </h3>

    <p>
      {deals.map((deal, index) => (
        <Button
          key={deal._id}
          basic
          color={deal._id === selectedDeal._id && 'violet'}
          fluid
          style={{ marginBottom: 10, textAlign: 'left' }}
          onClick={() => onSelect({ contract, deal })}
        >
          <b>SOW #{deals.length - index}</b>
          <p>{deal.position}</p>
          <DateFormat date={deal.effectiveDate} /> -&nbsp;
          {deal.endDate ? <DateFormat date={deal.endDate} /> : '(Active)'}
        </Button>
      ))}
    </p>
  </React.Fragment>
)

export default ContractsHistory
