import * as React from 'react'

import { Switch, Route } from 'react-router-dom'
import Candidates from './pages/candidates/candidates'
import Candidate from './pages/candidates/candidate'
import SideBar from './components/sidebar/sidebar'
// import DevMode from "./components/devMode";
import Positions from './pages/positions/positions'
import Position from './pages/positions/position'
import Recruiters from './pages/recruiters/recruiters'

import Templates from './pages/templates/templates'
import Tasks from './pages/tasks/tasks'

import CandidatesFunnel from './pages/funnels/candidatesFunnel'

import ContactsFunnel from './pages/funnels/contactsFunnel'

import Contacts from './pages/contacts/contacts'
import Contact from './pages/contacts/contact'

import Companies from './pages/companies/companies'
import Company from './pages/companies/company'

import Contracts from './pages/contracts/contracts'
import FunnelEdit from './pages/funnels/funnelEdit'

const App = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    }}
  >
    <div>
      <SideBar />
    </div>

    <div
      id="content"
      style={{
        width: '100%',
        padding: '0px 0px 0px 63px',
        minWidth: 1300,
      }}
    >
      <Switch>
        <Route path="/contracts" exact component={Contracts} />

        <Route path="/positions/:positionId" exact component={Position} />
        <Route path="/positions" exact component={Positions} />

        <Route path="/candidates/:candidateId" exact component={Candidate} />
        <Route path="/candidates" exact component={Candidates} />
        <Route
          path="/candidates/funnels/:funnelName"
          exact
          component={CandidatesFunnel}
        />
        <Route
          path="/candidates/funnels/:funnelName/edit"
          exact
          component={FunnelEdit}
        />

        <Route path="/contacts/:contactId" exact component={Contact} />
        <Route path="/contacts" exact component={Contacts} />
        <Route
          path="/contacts/funnels/:funnelName"
          exact
          component={ContactsFunnel}
        />

        <Route
          path="/contacts/funnels/:funnelName/edit"
          exact
          component={FunnelEdit}
        />

        <Route path="/companies/:companyId" exact component={Company} />
        <Route path="/companies" exact component={Companies} />

        <Route path="/recruiters" exact component={Recruiters} />
        <Route path="/templates" exact component={Templates} />
        <Route path="/tasks" exact component={Tasks} />
        <Route path="/" exact component={Candidates} />

        {/* <Route path="/" exact component={EmailForm} /> */}
      </Switch>
    </div>
  </div>
)

export default App
