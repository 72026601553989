import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PageLoader from '../../components/pageLoader'
import { useTalentApi } from '../../store/mainContext'
import { Link, useParams } from 'react-router-dom'
import PositionSidePanel from '../../components/sidepanels/positionSidePanel.js'
import { Breadcrumb, Icon, Menu } from 'semantic-ui-react'

import Feed from '../../components/feeds/feed'

import ContactName from '../../components/remoteValues/contactName'
import PositionCandidates from './positionCandidates'
import RecommendedCandidates from './recommendedCandidates'
import SubmittedCandidates from './submittedCandidates'

const Container = styled.div`
  display: flex;
  justify-content: space-between !important;
`

const MiddleColumn = styled.div`
  width: 100%;
  padding: 20px 20px;

  /* max-width: 600px; */
  min-width: 500px; /* background-color: var(--bg-grey); */
`

const LeftColumn = styled.div`
  margin-left: 10px;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  max-height: 100vh;
  /* overflow: auto; */
  /* position: sticky; */
  top: 0;

  padding-bottom: 100px;
  /* border-right: dotted 1px var(--border-grey); */
  padding-right: 10px;

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`

const Title = styled.h1`
  margin: 15px 20px 0px 30px !important;
`

const SubTitle = styled.div`
  margin: 5px 20px 0px 30px !important;
  font-size: 15px;
  color: #555;
`

const Position = () => {
  // const history = useHistory();

  // const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [selectedTab, setSelectedTab] = useState('feed')

  // const [rightView, setRightView] = useState('feed')

  const [position, setPosition] = useState()
  const [company, setCompany] = useState()

  const { positionId } = useParams()

  const api = useTalentApi()

  useEffect(() => {
    api
      .get(`positions/${positionId}`)
      .then(({ data }) => setPosition(data))
      .finally(() => setIsLoading(false))
  }, [api, positionId])

  useEffect(() => {
    const getCompanies = async () => {
      return await api.get(`companies/${position.companyId}`)
    }
    if (!position) {
      return
    }

    if (position.companyId) {
      const { data } = getCompanies()
      setCompany(data)
    }

    document.title = `${position.number} - ${position.title}`
  }, [api, position])

  if (isLoading) {
    return <PageLoader />
  }

  const handleChange = (name, value) => {
    const newPosition = { ...position }
    newPosition[name] = value
    newPosition.udpatedOn = Date.now()
    setPosition(newPosition)
  }

  // const backgroundColor = position.isPaused ? '#fffdf7' : null;

  return (
    <Container>
      <LeftColumn>
        <Breadcrumb style={{ padding: 15, marginLeft: 25 }}>
          <Breadcrumb.Section link>
            <Link to="/dashboard">Dashboard</Link>
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right chevron" />

          <Breadcrumb.Section link>
            <Link to="/positions">Positions</Link>
          </Breadcrumb.Section>
        </Breadcrumb>
        <Title>
          {position.number} - {position.title} {position.isPaused && '(Paused)'}
        </Title>
        <SubTitle>
          For{' '}
          {position.hiringManagerId && (
            <b>
              <ContactName id={position.hiringManagerId} />
            </b>
          )}{' '}
          {company && (
            <React.Fragment>
              at <b>{company.name}</b>
            </React.Fragment>
          )}
        </SubTitle>

        {position.sharePublicly ? (
          <SubTitle>
            This position is{' '}
            <span className="highlight">
              <b>
                being shared publicly <Icon name="rss" />
              </b>
            </span>
          </SubTitle>
        ) : (
          <SubTitle>
            This position is{' '}
            <span className="red">
              <b>
                not being shared publicly <Icon name="rss" />
              </b>
            </span>
          </SubTitle>
        )}

        <PositionSidePanel position={position} onChangeSuccess={handleChange} />
      </LeftColumn>
      <MiddleColumn>
        <Menu tabular>
          <Menu.Item
            icon="feed"
            name="Feed"
            active={selectedTab === 'feed'}
            onClick={() => setSelectedTab('feed')}
          />

          <Menu.Item
            icon="users"
            name="Candidates Pipeline"
            active={selectedTab === 'candidates'}
            onClick={() => setSelectedTab('candidates')}
          />

          <Menu.Item
            icon="lab"
            name="Recommended Candidates"
            active={selectedTab === 'reco-candidates'}
            onClick={() => setSelectedTab('reco-candidates')}
          />
        </Menu>

        {selectedTab === 'feed' && (
          <Feed relatedId={position._id} relatedCollection="positions" />
        )}

        {selectedTab === 'candidates' && (
          <PositionCandidates positionId={position._id} />
        )}

        {selectedTab === 'reco-candidates' && (
          <RecommendedCandidates
            minimumYearsOfExperience={position.minYears}
            profilesRequired={position.profilesRequired}
            techsRequired={position.techsRequired}
          />
        )}
      </MiddleColumn>

      {selectedTab === 'feed' && (
        <SubmittedCandidates positionId={position._id} />
      )}

      {/* {selectedTab === "feed" && (
        // <LinkedinViewer
        //   url={`https://auth0.openai.com/u/login/identifier?state=hKFo2SBWXzhVSU1uMll3V2c5MGdwOEtNRWdNS3FTNXlZZVpJYaFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFNYeU1vVkZXMmlOMUdIb3dneVlELUJzMDRVbGRUZlVSo2NpZNkgVGRKSWNiZTE2V29USHROOTVueXl3aDVFNHlPbzZJdEct`}
        // />
        <LinkedinViewer
          url={`https://www.perplexity.ai/?q=explain this position:${position.title}`}
        />
      )} */}
      {/* <RightColumn>
        <Menu secondary pointing>
          <Menu.Item
            onClick={() => setRightView("feed")}
            active={rightView === "feed"}
          >
            <Icon name="feed" />
            Feed
          </Menu.Item>

          <Menu.Item
            onClick={() => setRightView("changelog")}
            active={rightView === "changelog"}
          >
            <Icon name="magnify" />
            Change Log
          </Menu.Item>
        </Menu>

        <br />

        {rightView === "feed" && <PositionFeed position={position} />}
        {rightView === "changelog" && (
          <ChangeLog entityName="position" id={position._id} />
        )}
      </RightColumn> */}
    </Container>
  )
}

export default Position
