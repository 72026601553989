import React, { useEffect, useState } from 'react'

import { useTalentApi } from '../../store/mainContext'

// import EventEditor from '../eventEditor/eventEditor'
import {
  Event,
  PositionChangeEvent,
  SmallEvent,
  StatusChangeEvent,
  UserEvent,
  PositionPausedResumeEvent,
  PositionStatusChangeEvent,
  ActionEvent,
} from './eventTypes/event'

import Note from './eventTypes/note'
import Task from './eventTypes/task'
import PendingTask from './pendingTask'
// import ShowIf from '../showIf'
// import CreateTask from './createTask'
import {
  Button,
  Icon,
  // , Placeholder
} from 'semantic-ui-react'
import AddTaskModal from '../modals/addTaskModal'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AddLogModal from '../modals/addLogModal'
import FeedEditor from './feedEditor/feedEditor'
// import StatusNames from '../remoteValues/statusNames'
// import { timeout } from '../../utils'
// import PageLoader from '../pageLoader'
import FeedLoader from './feedLoader'
// import CollapsablePanel from '../collapsablePanel'

const NoTasksButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: dotted 1px var(--border-grey); */
  border-radius: 4px;
  padding: 20px;
  flex-direction: column;
`

const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
`

const Container = styled.div`
  margin-bottom: 40px;
`

const Feed = ({ relatedId, relatedCollection }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState([])

  const [pendingTasks, setPendingTasks] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [showAddLogModal, setShowAddLogModal] = useState(false)

  const api = useTalentApi()

  const refreshFeed = React.useCallback(async () => {
    const params = { relatedId }

    const { data } = await api.get(`events`, {
      params,
    })

    const { data: tasks } = await api.get(`tasks`, {
      params,
    })

    const doneTasks = tasks.filter((x) => x.isDone)

    const mix = [
      ...data.map((d) => ({ ...d, sortDate: d.createdOn })),
      ...doneTasks.map((d) => ({ ...d, sortDate: d.doneOn })),
    ]

    setEvents(
      mix.sort((a, b) => b.sortDate - a.sortDate || b.createdOn - a.createdOn)
    )
    // setEvents([]);
    setPendingTasks(
      tasks
        .filter((x) => !x.isDone)
        .sort((a, b) => a.dueDate - b.dueDate || b.createdOn - a.createdOn)
    )
  }, [api, relatedId])

  useEffect(() => {
    if (!relatedId) return
    refreshFeed().then(() => setIsLoading(false))
  }, [relatedId, refreshFeed])

  const handleTaskDone = async () => {
    setShowAddModal(true)
    await refreshFeed()
  }

  const handleTaskCreated = async () => {
    setShowAddModal(false)
    await refreshFeed()
  }

  const handleLogCreated = async () => {
    setShowAddLogModal(false)
    await refreshFeed()
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <FeedEditor
          relatedCollection={relatedCollection}
          relatedId={relatedId}
          onSuccess={refreshFeed}
        />

        <FeedLoader show={isLoading} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <FeedEditor
        relatedCollection={relatedCollection}
        relatedId={relatedId}
        onSuccess={refreshFeed}
      />

      <Container>
        <Header>
          <h3>Scheduled Tasks</h3>

          <Link
            style={{ marginLeft: 20 }}
            onClick={(e) => {
              e.preventDefault()
              setShowAddModal(true)
            }}
            to=""
          >
            [ + Add Task ]
          </Link>
        </Header>

        {pendingTasks.length > 0 ? (
          pendingTasks.map((t) => (
            <PendingTask
              key={t._id}
              task={t}
              onChangeSuccess={refreshFeed}
              onDone={handleTaskDone}
            />
          ))
        ) : (
          <NoTasksButton>
            <p>Nothing scheduled yet.</p>
            <Button basic onClick={() => setShowAddModal(true)}>
              <Icon name="add" /> Click here to add a task.
            </Button>
          </NoTasksButton>
        )}
      </Container>

      <Container>
        <Header>
          <h3>Events Feed</h3>

          <Link
            style={{ marginLeft: 20 }}
            onClick={(e) => {
              e.preventDefault()
              setShowAddLogModal(true)
            }}
            to=""
          >
            [ + Add Log ]
          </Link>
        </Header>

        {events.length > 0 ? (
          events.map((e, index) => {
            if (e.type === 'task') {
              return <Task key={e._id} task={e} onChangeSuccess={refreshFeed} />
            }

            const components = {
              'position-status-change': PositionStatusChangeEvent,
              'position-pause-resume': PositionPausedResumeEvent,
              'position-candidate-changed': PositionChangeEvent,
              'status-candidate-changed': StatusChangeEvent,
              'user-event': UserEvent,
              'pipedrive-note': Note,
              note: Note,
              'pto-requested': UserEvent,
              'account-created': UserEvent,
              'paymentSetup-changed': UserEvent,
              event: Event,
              'small-event': SmallEvent,
              action: ActionEvent,
            }

            const Component = components[e.type]

            if (!Component) {
              return <div key={e._id}>{JSON.stringify(e)}</div>
            }

            return (
              <Component
                event={e}
                key={e._id}
                onRemoveSuccess={refreshFeed}
                isFirstOne={index === events.length - 1}
              />
            )

            // if (e.type === "position-candidate-changed") {
            //   return (
            //     <PositionChangeEvent
            //       event={e}
            //       key={e._id}
            //       isFirstOne={index == events.length - 1}
            //     />
            //   );
            // }

            // if (e.type === "status-candidate-changed") {
            //   return (
            //     <StatusChangeEvent
            //       event={e}
            //       key={e._id}
            //       isFirstOne={index == events.length - 1}
            //     />
            //   );
            // }

            // // if (e.type === "email-sent") {
            // //   return (
            // //     <SmallEvent
            // //       event={e}
            // //       key={e._id}
            // //       isFirstOne={index == events.length - 1}
            // //     >
            // //       sent <b>{e.email}</b> email.
            // //     </SmallEvent>
            // //   );
            // // }

            // if (e.type === "user-event") {
            //   return (
            //     <UserEvent
            //       event={e}
            //       key={e._id}
            //       isFirstOne={index == events.length - 1}
            //     />
            //   );
            // }

            // if (e.type === "note") {
            //   return (
            //     <Note
            //       note={e}
            //       key={e._id}
            //       onRemoveSuccess={refreshFeed}
            //       isFirstOne={index === events.length - 1}
            //       disabled={e.type === "event"}
            //     />
            //   );
            // }

            // TODO: seguir aca.
            // return JSON.stringify(e);
          })
        ) : (
          <NoTasksButton>
            <p>Nothing logged yet.</p>
            <Button basic onClick={() => setShowAddLogModal(true)}>
              <Icon name="add" /> Click here to add a log.
            </Button>
          </NoTasksButton>
        )}
      </Container>

      <AddTaskModal
        show={showAddModal}
        relatedId={relatedId}
        relatedCollection={relatedCollection}
        onCancel={() => setShowAddModal(false)}
        onCreated={handleTaskCreated}
      />

      <AddLogModal
        show={showAddLogModal}
        relatedId={relatedId}
        relatedCollection={relatedCollection}
        onCancel={() => setShowAddLogModal(false)}
        onCreated={handleLogCreated}
      />
    </React.Fragment>
  )
}

export default Feed
