// import React from 'react';

import { isToday, isYesterday, isTomorrow } from '../utils'

const DateFormat = ({ date, hours, explain }) => {
  const d = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  if (!explain) {
    return hours ? `${d} ${new Date(date).toLocaleTimeString()}` : d
  }

  if (isToday(d)) {
    return 'Today'
  } else if (isYesterday(d)) {
    return 'Yesterday'
  } else if (isTomorrow(d)) {
    return 'Tomorrow'
  }

  return hours ? `${d} ${new Date(date).toLocaleTimeString()}` : d
}

export default DateFormat
