import React from 'react'
import styled from 'styled-components'
import UserName from '../../remoteValues/userName'
// import ContentEditable from "react-contenteditable";
import Entry from './entry'
import PositionLink from '../../remoteValues/positionLink'
import Period from '../../period'
import DateFormat from '../../dateFormat'
// import { Icon, Label } from "semantic-ui-react";
import StatusNames from '../../remoteValues/statusNames'
// import ShowIf from "../../showIf";
import Note from './note'

// const NoteTitle = styled.h2`
//   margin-top: 0px;
// `;

// const NoteContent = styled.div`
//   font-size: 16px !important;
//   line-height: 1.4em;
// `;

const Date = styled.div`
  margin-top: 0px !important;
  color: #666;
`

const What = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 20px;
`

// const Event = ({ event, isFirstOne }) => (
//   <Entry event={event} isFirstOne={isFirstOne}>
//     <Date>
//       <b>
//         <Period date={event.createdOn} />
//       </b>{" "}
//       - <DateFormat date={event.createdOn} hours /> - by{" "}
//       <UserName id={event.createdBy} />
//     </Date>

//     <NoteTitle>
//       {event.title} by <UserName id={event.createdBy} short />
//     </NoteTitle>

//     <NoteContent>
//       <ContentEditable html={event.description} disabled={true} />
//     </NoteContent>
//   </Entry>
// );

const Event = ({ event, isFirstOne }) => (
  <Note event={event} isFirstOne={isFirstOne} disabled={true} />
)

const SmallEvent = ({ event, isFirstOne, children, userId }) => (
  <Entry event={event} isFirstOne={isFirstOne} userId={userId}>
    <What>
      <UserName id={userId || event.createdBy} short />{' '}
      {children || event.description}
    </What>
    <Date>
      <b>
        <Period date={event.createdOn} />
      </b>{' '}
      - <DateFormat date={event.createdOn} hours />
    </Date>
  </Entry>
)

const ActionEvent = ({ event, isFirstOne, userId }) => (
  <Entry event={event} isFirstOne={isFirstOne} userId={userId}>
    <What dangerouslySetInnerHTML={{ __html: event.description }}></What>
    <Date>
      <b>
        <Period date={event.createdOn} />
      </b>{' '}
      - <DateFormat date={event.createdOn} hours />
    </Date>
  </Entry>
)

const PositionChangeEvent = ({ event, isFirstOne }) => {
  const { fromPositionId, toPositionId } = event

  if (!fromPositionId && !toPositionId) {
    return null
  }

  if (toPositionId && !fromPositionId) {
    return (
      <Entry event={event} isFirstOne={isFirstOne}>
        <What>
          <UserName id={event.createdBy} short /> <b>assigned</b> position{' '}
          <PositionLink positionId={toPositionId} />.
        </What>
        <Date>
          <b>
            <Period date={event.createdOn} />
          </b>{' '}
          - <DateFormat date={event.createdOn} hours />
        </Date>
      </Entry>
    )
  }

  if (!toPositionId && fromPositionId) {
    return (
      <Entry event={event} isFirstOne={isFirstOne}>
        <What>
          <UserName id={event.createdBy} short /> <b>removed</b> position{' '}
          <PositionLink positionId={fromPositionId} />.
        </What>
        <Date>
          <b>
            <Period date={event.createdOn} />
          </b>{' '}
          - <DateFormat date={event.createdOn} hours />
        </Date>
      </Entry>
    )
  }

  return (
    <Entry event={event} isFirstOne={isFirstOne}>
      <What>
        <UserName id={event.createdBy} short /> <b>changed</b> position from{' '}
        <PositionLink positionId={fromPositionId} /> to{' '}
        <PositionLink positionId={toPositionId} />
      </What>
      <Date>
        <b>
          <Period date={event.createdOn} />
        </b>{' '}
        - <DateFormat date={event.createdOn} hours />
      </Date>
    </Entry>
  )
}

const StatusChangeEvent = ({ event, isFirstOne }) => {
  const { fromStatus, toStatus } = event

  if (!fromStatus && !toStatus) {
    return null
  }

  if (toStatus && !fromStatus) {
    return (
      <SmallEvent
        isFirstOne={isFirstOne}
        userId={event.createdBy}
        event={event}
      >
        moved this candidate to{' '}
        <b>
          <StatusNames status={toStatus} showLoading />
        </b>{' '}
        stage.
      </SmallEvent>
    )
  }

  if (!toStatus && fromStatus) {
    return (
      <SmallEvent
        isFirstOne={isFirstOne}
        userId={event.createdBy}
        event={event}
      >
        remove this candidate from{' '}
        <b>
          <StatusNames status={fromStatus} showLoading />
        </b>{' '}
        stage.
      </SmallEvent>
    )
  }

  return (
    <SmallEvent isFirstOne={isFirstOne} userId={event.createdBy} event={event}>
      moved this candidate from{' '}
      <b>
        <StatusNames status={fromStatus} showLoading />
      </b>{' '}
      to{' '}
      <b>
        <StatusNames status={toStatus} showLoading />
      </b>{' '}
      stage.
    </SmallEvent>
  )
}

const UserEvent = ({ event, isFirstOne }) => {
  return (
    <Entry event={event} isFirstOne={isFirstOne} userImage={event.userImage}>
      <What dangerouslySetInnerHTML={{ __html: event.title }}></What>
      {event.description && (
        <p style={{ fontSize: '14px', margin: 10 }}>
          <span dangerouslySetInnerHTML={{ __html: event.description }} />
        </p>
      )}
      <Date>
        <b>
          <Period date={event.createdOn} />
        </b>{' '}
        - <DateFormat date={event.createdOn} hours />
      </Date>
    </Entry>
  )
}

const PositionPausedResumeEvent = ({ event, isFirstOne }) => (
  <SmallEvent event={event} isFirstOne={isFirstOne}>
    {event.isNowPaused ? <b>paused</b> : <b>resumed</b>} this position.
  </SmallEvent>
)

const PositionStatusChangeEvent = ({ event, isFirstOne }) => (
  <SmallEvent event={event} isFirstOne={isFirstOne}>
    {event.newStatus === 'closed' ? <b>closed</b> : <b>re-open</b>} this
    position.
  </SmallEvent>
)

export default Event

export {
  Event,
  PositionChangeEvent,
  SmallEvent,
  StatusChangeEvent,
  UserEvent,
  PositionPausedResumeEvent,
  PositionStatusChangeEvent,
  ActionEvent,
}
