import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { Button, Icon, Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'

const Title = styled.h2`
  font-weight: bold !important;
`

const Editable = styled(ContentEditable)`
  border: solid 1px var(--border-grey);
  margin-top: 20px;
  padding: 15px;
  font-size: 17px;
  line-height: 1.4em;
  padding-bottom: 30px;
  border-radius: 4px;
  height: 300px;
  max-height: 300px;
  overflow: auto;

  &:focus {
    outline: none;

    border: solid 1px var(--primary);
    /* background-color: var(--primary-light); */
    box-shadow: var(--active-shadow);
  }
`

// const selectElementContents = (el) => {
//   var range = document.createRange()
//   range.selectNodeContents(el)
//   var sel = window.getSelection()
//   sel.removeAllRanges()
//   sel.addRange(range)
// }

const AddLogModal = ({
  show,
  relatedCollection,
  relatedId,
  onCancel,
  onCreated,
}) => {
  const editor = useRef(null)
  const [active, setActive] = useState('Note')
  const [isSaving, setIsSaving] = useState(false)

  const defaultValue = 'Say something smart...'
  const [value, setValue] = useState(defaultValue)

  const api = useTalentApi()

  useEffect(() => {
    setValue(defaultValue)
    setActive('Note')

    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }, [show])

  const handleSave = async () => {
    setIsSaving(true)

    const obj = {
      description: value,
      title: active,
      type: 'note',
      relatedId,
      relatedCollection,
    }

    await api.post('events', obj)

    onCreated && onCreated()

    setIsSaving(false)
  }

  const handleFocus = () => {
    if (value === defaultValue) {
      setValue('')
    }
  }

  const handleOnBlur = (e) => {
    if (e.target.innerHTML === '') {
      setValue(defaultValue)
    }
  }

  if (!show) {
    return null
  }

  return (
    <Modal onClose={onCancel} open={true} size="big" closeOnDimmerClick={false}>
      <Modal.Content>
        <Title>What you wanna log?</Title>

        <Button.Group basic style={{ width: `100%` }} disabled={isSaving}>
          <Button
            active={active === 'Screening Notes'}
            disabled={isSaving}
            onClick={() => setActive('Screening Notes')}
          >
            Screening
            <br />
            Notes
          </Button>

          <Button
            active={active === 'Customer Interview'}
            disabled={isSaving}
            onClick={() => setActive('Customer Interview')}
          >
            Customer
            <br />
            Interview
          </Button>

          <Button
            active={active === 'Customer Feedback'}
            disabled={isSaving}
            onClick={() => setActive('Customer Feedback')}
          >
            Customer
            <br />
            Feedback
          </Button>

          <Button
            active={active === 'Candidate Feedback'}
            disabled={isSaving}
            onClick={() => setActive('Candidate Feedback')}
          >
            Candidate
            <br />
            Feedback
          </Button>

          <Button
            active={active === 'Email or Chat'}
            disabled={isSaving}
            onClick={() => setActive('Email or Chat')}
          >
            Email or Chat
          </Button>

          <Button
            active={active === 'Meeting'}
            disabled={isSaving}
            onClick={() => setActive('Meeting')}
          >
            Meeting
          </Button>

          <Button
            active={active === 'Note'}
            disabled={isSaving}
            onClick={() => setActive('Note')}
          >
            Other
          </Button>
        </Button.Group>

        <Editable
          html={value}
          disabled={isSaving}
          onChange={(e) => setValue(e.target.value.trim())}
          ref={editor}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          style={{ color: value === defaultValue && '#666' }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel}>
          Nervemind...
        </Button>
        <Button
          disabled={!value || value === defaultValue || value.length < 4}
          loading={isSaving}
          color="black"
          onClick={handleSave}
        >
          <Icon name="save" /> Save!{' '}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddLogModal
