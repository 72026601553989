import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Popup, Dropdown, DropdownItem } from 'semantic-ui-react'

import './sidebar.css'

import { useUser, useSessionActions } from '../../pages/session/hooks'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'
import RequirePowers from '../requirePowers'
import POWERS from '../../powers'
import { socket } from '../../socket'

const ItemContainer = styled.div`
  position: relative;
`

const Notification = styled.div`
  position: absolute;
  top: 2px;
  right: 9px;
  background-color: var(--red);
  color: #fff;
  text-align: center;
  min-width: 22px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 7px;
  padding: 2px;
  font-size: 12px;
  font-weight: bold;
`

const Divider = styled.hr`
  border: none;
  border-bottom: dotted 1px var(--border-grey);
`

const MenuItem = ({ icon, text, linkTo, disabled, notifications }) => {
  const history = useHistory()
  const location = useLocation()

  const handleOnClick = useCallback(
    () => linkTo && history.push(linkTo),
    [history, linkTo]
  )

  const active = location.pathname.indexOf(linkTo) === 0

  return (
    <ItemContainer>
      <Popup
        trigger={
          <div
            className={
              'sideMenuItem ' + (active && 'active') + (disabled && ' disabled')
            }
            onClick={handleOnClick}
          >
            <i className={`icon ${icon}`}></i>
          </div>
        }
        content={text}
        position="right center"
        offset={[0, 5]}
      ></Popup>

      {notifications > 0 && <Notification>{notifications}</Notification>}
    </ItemContainer>
  )
}

const MenuWithSubMenuItem = ({ icon, text, linkTo, subMenu, router }) => {
  const location = useLocation()
  const history = useHistory()

  const active = location.pathname.indexOf(linkTo) === 0

  const [showSubMenu, setShowSubMenu] = useState()

  return (
    <React.Fragment>
      <Dropdown
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
        open={showSubMenu}
        icon={null}
        pointing="left"
        fluid
        style={{ left: -20 }}
      >
        <Dropdown.Menu style={{ zIndex: 9999 }}>
          {subMenu.map((x) => (
            <DropdownItem
              onClick={() => {
                setShowSubMenu(false)
                history.push(x.linkTo)
              }}
              style={{ zIndex: 9999 }}
              key={x.text}
            >
              {x.text}
            </DropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div
        className={'sideMenuItem ' + (active && 'active')}
        onMouseEnter={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
        onClick={() => history.push(linkTo)}
      >
        <i className={`icon ${icon}`}></i>
      </div>
    </React.Fragment>
  )
}

const SideBar = () => {
  const user = useUser()
  const { logOut } = useSessionActions()

  const [, setShowUserMenu] = useState(false)

  const [tasks, setTasks] = useState(0)

  const [funnels, setFunnels] = useState([])

  const api = useTalentApi()

  const handleTaskUpdated = React.useCallback(() => {
    const params = {
      assignedToId: user._id,
      isDone: { $ne: true },
      dueDate: { $lte: Date.now() }, // TODO: IMPROVE TODAY CONCEPT.
    }
    api.get(`tasks/pro`, { params }).then(({ data }) => setTasks(data.length))
  }, [api, user._id])

  useEffect(() => {
    socket.on('tasks-updated', handleTaskUpdated)
    return () => {
      socket.off('tasks-updated', handleTaskUpdated)
    }
  }, [handleTaskUpdated])

  useEffect(() => {
    handleTaskUpdated()
  }, [handleTaskUpdated])

  useEffect(() => {
    api.get('funnels').then(({ data }) => setFunnels(data))
  }, [api])

  return (
    <React.Fragment>
      <div id="sidebar">
        <div style={{ padding: 10 }} className="logo">
          <Dropdown
            trigger={
              <img
                alt={user.name}
                style={{ borderRadius: 17, height: 35 }}
                src={user.image}
                onMouseOver={(x) => setShowUserMenu(true)}
              />
            }
            icon={null}
            pointing="left"
            fluid
            style={{ textAlign: 'center' }}
          >
            <Dropdown.Menu style={{ zIndex: 9999 }}>
              <DropdownItem>
                <span>
                  Signed in as <strong>{user.name}</strong>
                </span>
              </DropdownItem>
              <DropdownItem>Your Profile</DropdownItem>
              <DropdownItem>Settings</DropdownItem>
              <DropdownItem onClick={logOut}>Sign Out</DropdownItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <MenuItem
          icon="chart area"
          text="Dashboard"
          linkTo="/dashboard"
          disabled
        />

        <Divider />

        <MenuItem
          icon="tasks"
          text="Tasks"
          linkTo="/tasks"
          notifications={tasks}
        />

        <Divider />

        <MenuWithSubMenuItem
          icon="map outline"
          linkTo={`/candidates/funnels/${
            (
              funnels
                .filter((x) => x.collection === 'candidates')
                .sort((a, b) => a.order - b.order)[0] || {}
            ).name
          }`}
          text="Candidates Funnels"
          subMenu={funnels
            .filter((x) => x.collection === 'candidates')
            .sort((a, b) => a.order - b.order)
            .map((f) => ({
              text: f.title,
              linkTo: `/candidates/funnels/${f.name}`,
            }))}
        />

        <MenuItem
          icon="user circle outline"
          text="Candidates"
          linkTo="/candidates"
        />

        <MenuItem
          icon="newspaper outline
"
          text="Positions"
          linkTo="/positions"
        />

        <RequirePowers powers={[POWERS.contactsAndCompanies]}>
          <Divider />

          <MenuWithSubMenuItem
            icon="map"
            linkTo={`/contacts/funnels/${
              (
                funnels
                  .filter((x) => x.collection === 'contacts')
                  .sort((a, b) => a.order - b.order)[0] || {}
              ).name
            }`}
            text="Contacts Funnels"
            subMenu={funnels
              .filter((x) => x.collection === 'contacts')
              .sort((a, b) => a.order - b.order)
              .map((f) => ({
                text: f.title,
                linkTo: `/contacts/funnels/${f.name}`,
              }))}
          />

          <MenuItem icon="user circle" text="Contacts" linkTo="/contacts" />

          <MenuItem icon="building" text="Companies" linkTo="/companies" />
        </RequirePowers>

        <RequirePowers powers={[POWERS.contracts]}>
          <Divider />

          <MenuItem
            icon="address card outline"
            text="Contracts"
            linkTo="/contracts"
          />
        </RequirePowers>

        <RequirePowers powers={[POWERS.settings]}>
          <Divider />

          <MenuItem icon="chess queen" text="Recruiters" linkTo="/recruiters" />

          <Divider />

          <MenuItem icon="settings" text="Settings" linkTo="/templates" />
        </RequirePowers>
      </div>
    </React.Fragment>
  )
}

export default SideBar
