import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
// import { Link } from 'react-router-dom'
import {
  Button,
  Confirm,
  Dropdown,
  DropdownItem,
  Icon,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../../store/mainContext'
import DateFormat from '../../dateFormat'
import Period from '../../period'
import UserName from '../../remoteValues/userName'
import ShowIf from '../../showIf'
// import Entry from './entry'
import { SmallEvent } from './event'

const Content = styled.div`
  position: relative;
  border: dotted 1px var(--border-grey);
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  &:hover {
    background-color: #fcfcfc;
  }
`

const EditContent = styled.div`
  position: relative;
  box-shadow: var(--active-shadow) !important;
  border-color: var(--primary) !important;

  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  align-items: flex-start;
  cursor: pointer;
`

const Title = styled.h2`
  margin: 0px;
  width: 100%;
  padding-left: 15px;
`

const Details = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Split = styled.div`
  border-top: dotted 1px var(--border-grey);
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
`

const DoneNote = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Sub = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 20px;
`

const Date = styled.h3`
  margin-top: 0px !important;
  margin-bottom: 5px !important;
`

const Menu = styled.div`
  position: absolute;
  font-size: 15px;
  top: 0px;
  right: 0px;
  z-index: 90;
`

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
`

const Editor = styled(ContentEditable)`
  /* width: 100%; */

  &:focus {
    outline: none;
  }
`

const ContentBottom = styled.div`
  height: 50px;
  border-left: dotted 1px var(--border-grey);
  margin-left: 40px;
`

const ViewMoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
`

const Task = ({ task, isFirstOne, disabled, onChangeSuccess }) => {
  const editor = useRef(null)
  const api = useTalentApi()

  const [currentTask, setCurrentTask] = useState({ ...task })

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showConfirmNotDoneModal, setShowConfirmNotDoneModal] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)

  const collapse = !editMode && isCollapsed
  const Contaier = editMode ? EditContent : Content

  useEffect(() => {
    if (!task) {
      return
    }

    // By default is collapsed if doen't have content.
    if (!task.details && !task.doneNote) {
      setIsCollapsed(true)
    }
  }, [task])

  useEffect(() => {
    setCurrentTask({ ...task })
  }, [task])

  useEffect(() => {
    if (editMode && editor) {
      editor.current.el.current.focus()
    }
  }, [editMode])

  const handleCancel = () => {
    setCurrentTask({ ...task })
    setEditMode(false)
  }

  const handleSave = async () => {
    setIsSaving(true)

    const { subject, details, doneNote } = currentTask

    const update = {}

    if (task.subject !== subject) {
      update.subject = subject
    }

    if (task.details !== details) {
      update.details = details
    }

    if (task.doneNote !== doneNote) {
      update.doneNote = doneNote
    }

    if (update.subject || update.details || update.doneNote) {
      await api.patch(`tasks/${task._id}`, update)
    }

    onChangeSuccess && onChangeSuccess()

    setIsSaving(false)
    setEditMode(false)
  }

  const handleMarkNotDone = async () => {
    setIsRemoving(true)

    await api.patch(`tasks/${task._id}`, { isDone: false })
    await api.delete(`tasks/${task._id}/doneNote`)

    setIsRemoving(false)
    setShowConfirmNotDoneModal(false)
    onChangeSuccess && onChangeSuccess()
  }

  const handleConfirmRemove = async () => {
    setIsRemoving(true)
    await api.delete(`tasks/${task._id}`)

    onChangeSuccess && onChangeSuccess()

    setIsRemoving(false)
    setShowConfirmModal(false)
  }

  return (
    <React.Fragment>
      <ShowIf if={!task.doneNote && !editMode}>
        <SmallEvent event={task} userId={task.doneBy}>
          <b>completed</b> &quot;{currentTask.subject}&quot; task.
          <Dropdown
            icon="ellipsis horizontal"
            direction="left"
            style={{ marginLeft: 10 }}
          >
            <Dropdown.Menu>
              <DropdownItem onClick={() => setEditMode(true)}>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => setShowConfirmNotDoneModal(true)}>
                Mark as <b>Not Done</b>
              </DropdownItem>
              <DropdownItem onClick={() => setShowConfirmModal(true)}>
                <span style={{ color: 'red' }}>Remove</span>
              </DropdownItem>
            </Dropdown.Menu>
          </Dropdown>
          <ShowIf if={task.details}>
            <p style={{ fontSize: '14px', margin: 10 }}>
              <b>Details:</b>{' '}
              <span dangerouslySetInnerHTML={{ __html: task.details }} />
            </p>
          </ShowIf>
        </SmallEvent>
      </ShowIf>

      <ShowIf if={task.doneNote || editMode}>
        <Contaier>
          <Header onClick={() => !editMode && setIsCollapsed(!isCollapsed)}>
            <div style={{ marginTop: 3 }}>
              <UserName id={currentTask.doneBy} image height={45} />
            </div>

            <Title>
              <Date>
                <b>
                  <Period date={currentTask.doneOn} />
                </b>{' '}
                - <DateFormat date={currentTask.doneOn} hours /> - by{' '}
                <UserName id={currentTask.doneBy} />
              </Date>
              <div style={{ display: 'flex' }}>
                {/* <div>&nbsp;</div> */}
                <Editor
                  html={currentTask.subject}
                  disabled={!editMode}
                  onChange={(e) =>
                    setCurrentTask({ ...currentTask, subject: e.target.value })
                  }
                  name="subject"
                />
                &nbsp;<span style={{ fontSize: 15 }}>(Done)</span>
              </div>
            </Title>

            <ShowIf if={!disabled && !editMode}>
              <Menu>
                <ViewMoreButton>
                  <ShowIf
                    if={collapse}
                    true={<Icon name="chevron down" />}
                    false={<Icon name="chevron up" />}
                  />
                </ViewMoreButton>

                <Dropdown icon="ellipsis horizontal" direction="left">
                  <Dropdown.Menu>
                    <DropdownItem onClick={() => setEditMode(true)}>
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setShowConfirmNotDoneModal(true)}
                    >
                      Mark as <b>Not Done</b>
                    </DropdownItem>
                    <DropdownItem onClick={() => setShowConfirmModal(true)}>
                      <span style={{ color: 'red' }}>Remove</span>
                    </DropdownItem>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            </ShowIf>
          </Header>

          <ShowIf if={!collapse}>
            <ShowIf if={currentTask.details || editMode}>
              <Details>
                <h3>
                  <Icon name="clipboard outline" />
                  <b>Details</b>
                </h3>
                <Editor
                  name="details"
                  html={currentTask.details || ''}
                  disabled={!editMode}
                  ref={editor}
                  onChange={(e) =>
                    setCurrentTask({ ...currentTask, details: e.target.value })
                  }
                />
              </Details>
            </ShowIf>

            <ShowIf if={currentTask.requireDoneNote}>
              <Split />
              <DoneNote>
                <h3>
                  <Icon name="clipboard outline" />
                  <b>Notes</b>
                </h3>
                <Editor
                  name="doneNote"
                  html={currentTask.doneNote}
                  disabled={!editMode}
                  ref={editor}
                  onChange={(e) =>
                    setCurrentTask({ ...currentTask, doneNote: e.target.value })
                  }
                />
              </DoneNote>
            </ShowIf>

            <ShowIf if={editMode}>
              <ButtonBar>
                <Button basic disabled={isSaving} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button color="black" onClick={handleSave} loading={isSaving}>
                  <Icon name="save"></Icon>Save
                </Button>
              </ButtonBar>
            </ShowIf>

            <Sub>
              Task created by{' '}
              <b>
                <UserName id={currentTask.createdBy} />
              </b>{' '}
              on <DateFormat date={currentTask.createdOn} hours /> and assigned
              to{' '}
              <b>
                <UserName id={currentTask.assignedToId} />
              </b>
              .
            </Sub>
          </ShowIf>
        </Contaier>

        <ShowIf if={!isFirstOne} true={<ContentBottom />} />
      </ShowIf>

      <Confirm
        open={showConfirmModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmRemove}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this task.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... removing the task?"
        confirmButton={
          <Button primary={false} color="red" loading={isRemoving}>
            Sure, remove.
          </Button>
        }
      />

      <Confirm
        open={showConfirmNotDoneModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmNotDoneModal(false)}
        onConfirm={handleMarkNotDone}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to mark this task as <b>Not Done</b>.
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... mark as Not done?"
        confirmButton={
          <Button primary={false} color="black" loading={isRemoving}>
            Sure, mark as not done.
          </Button>
        }
      />
    </React.Fragment>
  )
}

export default Task
