import React, {
  useEffect,
  // , useRef
  useState,
} from 'react'
import ContentEditable from 'react-contenteditable'
import {
  Button,
  Icon,
  Input,
  Menu,
  // , MenuHeader
} from 'semantic-ui-react'
import styled from 'styled-components'
import PageHeader from '../../components/pageHeader/pageHeader'
import Editor from '@monaco-editor/react'
import FormField from '../../components/form/formField'
// import { useUser } from '../session/hooks'
import { useTalentApi } from '../../store/mainContext'
import PageLoader from '../../components/pageLoader'

const PageContent = styled.div`
  margin: 20px 40px;
  display: flex;
`

const Container = styled.div`
  width: 80%;
  margin-left: 30px;
  position: relative;
`

// const Sub = styled.p`
//   color: #666;
// `

const CodeEditor = styled(Editor)`
  border: solid 1px var(--border-grey);
  border-radius: 4px;

  &:focus {
    border-color: red !important;
  }
`

// const EmailInput = styled(Input)`
//   width: 300px;
//   height: 37px;
// `

// const Frame = styled.iframe`
//   width: 100%;
//   height: 500px;
//   border: dotted 1px var(--border-grey);
// `

const Previewer = styled(ContentEditable)`
  padding: 20px;
  border: dotted 1px var(--border-grey);
`

const Templates = () => {
  document.title = 'Templates'

  return (
    <React.Fragment>
      <PageHeader
        // breadcrumb={[
        //   { text: "Dashboard", link: "/" },
        //   { text: "Templates", link: "/templates" },
        // ]}

        title="Email Templates"
        sub="One Screen to template them all."
        // views={["Recruiters Email"]}
      />

      <PageContent>
        <Menu secondary vertical>
          <Menu.Item header>Positions</Menu.Item>
          <Menu.Item
            name="Positions Email"
            active
            onClick={() => alert('No Yet')}
          />
          <Menu.Item header>&nbsp;</Menu.Item>
          <Menu.Item header>Candidates</Menu.Item>
          <Menu.Item
            name="Interview Scheduled"
            onClick={() => alert('No yet')}
          />
          <Menu.Item name="Retouch" onClick={() => alert('No yet')} />

          <Menu.Item name="Position on Hold" onClick={() => alert('No yet')} />

          <Menu.Item header>&nbsp;</Menu.Item>
          <Menu.Item header>Contractors</Menu.Item>

          <Menu.Item name="Getting Started" onClick={() => alert('No yet')} />

          <Menu.Item name="1st Month Present" onClick={() => alert('No yet')} />

          <Menu.Item name="PTO Approved" onClick={() => alert('No yet')} />
        </Menu>

        <TemplateEditor name="positions-email" title={'Positions Email'} />
      </PageContent>
    </React.Fragment>
  )
}

export default Templates

const TemplateEditor = ({ name, title }) => {
  // const iframe = useRef()

  // const user = useUser()
  const api = useTalentApi()

  const [preview, setPreview] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  const [value, setValue] = useState({})
  // const [testEmailAddress, setTestEmailAddress] = useState(user.email)

  useEffect(() => {
    setIsLoading(true)
    api
      .get(`templates`, { params: { name } })
      .then(({ data }) => {
        api.get(`public/emails/recruiting`).then(({ data: html }) => {
          setPreview(html.message)
          setValue(data[0])
        })
      })
      .finally(() => setIsLoading(false))
  }, [api, name])

  const handleSave = async () => {
    setIsSaving(true)

    const { subject, body, position } = value

    await api.patch(`templates/${value._id}`, { subject, body, position })

    const { data: html } = await api.get(`public/emails/recruiting`)

    setPreview(html.message)

    setIsSaving(false)
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Container>
      <h2>{title}</h2>

      <FormField label="Subject">
        <Input
          disabled={isSaving}
          fluid
          placeholder="Email subject..."
          onChange={(e) => setValue({ ...value, subject: e.target.value })}
          value={value?.subject}
        />
      </FormField>

      <FormField label="Email Body">
        <CodeEditor
          height="400px"
          defaultLanguage="html"
          defaultValue="<p>Hi [Somebody]</p>"
          theme="vs-dark"
          scrollbarVisibility={2}
          options={{
            minimap: { enabled: false },
            lineNumbers: 'off',
            padding: { top: 10 },
            wordWrap: 'on',
            fontSize: 14,
            readOnly: isSaving,
          }}
          value={value?.body}
          onChange={(code) => setValue({ ...value, body: code })}
        />
      </FormField>

      <FormField label="Position Detail">
        <CodeEditor
          height="200px"
          defaultLanguage="html"
          defaultValue="<p>Hi [Somebody]</p>"
          theme="vs-dark"
          scrollbarVisibility={2}
          options={{
            minimap: { enabled: false },
            lineNumbers: 'off',
            padding: { top: 10 },
            wordWrap: 'on',
            fontSize: 14,
            readOnly: isSaving,
          }}
          value={value?.position}
          onChange={(code) => setValue({ ...value, position: code })}
        />
      </FormField>

      <FormField actions>
        {/* <EmailInput 
floated="left" 
value={testEmailAddress} 
onChange={e => setTestEmailAddress(e.target.value)} 
action={{ basic: true, icon: 'lab', content: 'Test!', onClick: () => alert('no yet.') }}

/> */}

        <Button
          color="black"
          floated="right"
          onClick={handleSave}
          loading={isSaving}
        >
          <Icon name="save" /> Save
        </Button>
        <Button basic floated="right" disabled={isSaving}>
          Cancel
        </Button>
      </FormField>

      <h2>Preview</h2>

      <Previewer html={preview} disabled={true} />
    </Container>
  )
}
