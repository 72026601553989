import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Icon, Select } from 'semantic-ui-react'
import ShowIf from '../showIf'

const ColumnButton = styled.div`
  cursor: pointer;
  border: dotted 1px var(--border-grey);
  padding: 10px;
  border-radius: 4px;
  /* background-color: var(--primary-light); */
  background-color: #fff;

  &:hover {
    border: solid 1px var(--border-primary);
    background-color: var(--primary-light);
    /* box-shadow: var(--active-shadow); */
  }
`

const ColumnButtonActive = styled.div`
  cursor: pointer;
  border: solid 1px var(--border-primary);
  padding: 10px;
  border-radius: 4px;

  color: var(--primary);
  background-color: var(--primary-light);
  font-weight: bold;
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const StatusSelector = ({
  funnels = [],
  value,
  onCancel,
  onSave,
  onRemove,
}) => {
  const [selectedColumn, setSetSelectedColumn] = useState(value)
  const [selectedFunnel, setSelectedFunnel] = useState({})

  useEffect(() => {
    if (!value) {
      setSelectedFunnel(funnels[0])
      setSetSelectedColumn(funnels[0].columns[0])
      return
    }

    const funnelName = selectedColumn.id.split('.')[0]
    const funnel = funnels.find((x) => x.name === funnelName)

    setSelectedFunnel(funnel)
  }, [funnels, selectedColumn.id, value])

  return (
    <React.Fragment>
      <div style={{ textAlign: 'left', marginBottom: 10 }}>
        <b>Funnel</b>
      </div>
      <Select
        options={funnels
          .sort((a, b) => a.order - b.order)
          .map((x) => ({ key: x._id, value: x._id, text: `${x.title}` }))}
        value={selectedFunnel._id}
        fluid
        style={{ marginBottom: 20 }}
        onChange={(e, target) =>
          setSelectedFunnel(funnels.find((x) => x._id === target.value))
        }
      />

      <div style={{ textAlign: 'left', marginBottom: 10 }}>
        <b>Stage</b>
      </div>
      {(selectedFunnel.columns || []).map((column, index) => (
        <React.Fragment key={column.id}>
          <ShowIf if={column.id === selectedColumn.id}>
            <ColumnButtonActive>{column.title}</ColumnButtonActive>
          </ShowIf>

          <ShowIf if={column.id !== selectedColumn.id}>
            <ColumnButton onClick={() => setSetSelectedColumn(column)}>
              {column.title}
            </ColumnButton>
          </ShowIf>

          {index !== (selectedFunnel.columns || []).length - 1 && (
            <Icon name="arrow down" />
          )}
        </React.Fragment>
      ))}

      <ButtonBar>
        <Button color="red" size="tiny" onClick={onRemove}>
          Remove Stage
        </Button>

        <div>
          <Button basic size="tiny" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="black"
            size="tiny"
            onClick={() => onSave(selectedColumn)}
          >
            Save
          </Button>
        </div>
      </ButtonBar>
    </React.Fragment>
  )
}

export default StatusSelector
