import React, {
  // useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Dropdown,
  // DropdownDivider,
  DropdownItem,
  Icon,
} from 'semantic-ui-react'
import styled from 'styled-components'
import ConfirmRemoveModal from '../modals/confirmRemoveModal'

import AddTaskModal from '../modals/addTaskModal'
import AddLogModal from '../modals/addLogModal'
import RequirePowers from '../requirePowers'
// import ShowIf from "../showIf";
// import ProvisioningModal from "../modals/addAccountModal";
// import AddContractModal from "../modals/addContractModal";
import AddAccountModal from '../modals/addAccountModal'
import POWERS from '../../powers'
import { useTalentApi } from '../../store/mainContext'

const Container = styled.div`
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`

const B = styled.div`
  text-align: center;
  margin-right: 20px;
  min-width: 55px;

  &:last-child {
    margin-right: 0px;
  }
`

const Label = styled.div`
  margin-top: 5px;
`

const CandidateActions = ({ candidate, contract, onSuccess }) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)
  const [showAddLogModal, setShowAddLogModal] = useState(false)
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false)
  // const [showAddContractModal, setShowAddContractModal] = useState(false)
  const [showConfirmRemoveAccount, setShowConfirmRemoveAccount] =
    useState(false)

  // const [activeContract, setActiveContract] = useState()

  const history = useHistory()
  const api = useTalentApi()

  // const contractsApi = useContractsApi()

  // useEffect(() => {
  //   if (!candidate) {
  //     return
  //   }

  //   // try {
  //   //   const { data } = await contractsApi.get(
  //   //     `/${candidate._id}/active-contract`
  //   //   );
  //   //   setActiveContract(data);
  //   // } catch (e) {}
  // }, [candidate])

  const handleRemove = async () => {
    await api.delete(`candidates/${candidate._id}`)

    setShowConfirmRemove(false)

    history.push(`/candidates`)
  }

  const handleTaskCreated = () => {
    setShowAddTaskModal(false)
    onSuccess && onSuccess()
  }

  const handleLogCreated = () => {
    setShowAddLogModal(false)
    onSuccess && onSuccess()
  }

  const handleAccountCreated = (data) => {
    setShowCreateAccountModal(false)
    onSuccess && onSuccess('southEmail', data.southEmail)
  }

  const handleRemoveAccount = async () => {
    await api.delete(`/user-accounts/${candidate._id}`)
    setShowConfirmRemoveAccount(false)
    onSuccess && onSuccess('southEmail', null)

    // setShowAddContractModal(false);
    // onSuccess && onSuccess();
  }

  return (
    <React.Fragment>
      <Container>
        <B>
          <Button
            color="red"
            circular
            icon="trash alternate outline"
            onClick={() => setShowConfirmRemove(true)}
          ></Button>
          <Label>Remove</Label>
        </B>

        <B>
          <Button
            basic
            circular
            icon="mail"
            onClick={() =>
              (document.location.href = `mailto:${candidate.email}`)
            }
          ></Button>
          <Label>Mail</Label>
        </B>

        <B>
          <Button
            basic
            circular
            icon="share"
            onClick={() => alert('Not ready yet. Sorry.')}
          ></Button>
          <Label>Share</Label>
        </B>

        <B>
          <Dropdown
            pointing="top right"
            icon={null}
            trigger={
              <Button
                color="black"
                circular
                icon="ellipsis horizontal"
              ></Button>
            }
          >
            <Dropdown.Menu>
              <RequirePowers powers={[POWERS.userAccounts]}>
                {!candidate.southEmail && (
                  <DropdownItem
                    disabled={
                      !candidate.email ||
                      candidate.southEmail ||
                      !contract ||
                      !contract.active
                    }
                    onClick={() => setShowCreateAccountModal(true)}
                  >
                    <Icon name="add" /> Create Account
                  </DropdownItem>
                )}

                {candidate.southEmail && (
                  <DropdownItem
                    disabled={contract && contract.active}
                    onClick={() => setShowConfirmRemoveAccount(true)}
                  >
                    <div style={{ color: 'var(--red)' }}>
                      <Icon name="trash alternate outline" /> Remove Account
                    </div>
                  </DropdownItem>
                )}
              </RequirePowers>

              {/* <DropdownItem
                disabled={activeContract}
                onClick={() => setShowAddContractModal(true)}
              >
                <Icon name="add" /> Add Contract
              </DropdownItem> */}

              {/* <ShowIf if={!candidate.southEmail && offer && offer.agreement}>
                <DropdownDivider></DropdownDivider>

                <DropdownItem onClick={() => setShowProvisiningModal(true)}>
                  <Icon name="flag checkered" /> Provision as new contractor
                </DropdownItem>
              </ShowIf> */}
            </Dropdown.Menu>
          </Dropdown>

          <Label>More</Label>
        </B>
      </Container>

      <ConfirmRemoveModal
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemove(false)}
        show={showConfirmRemove}
        validationValue={candidate.name}
        header="Hey...Removing this candidate?"
        content={
          <React.Fragment>
            <p>
              You are about to remove{' '}
              <b className="highlight">{candidate.name}</b>.{' '}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter candidate&apos;s name to confirm.</b>
            </p>
          </React.Fragment>
        }
      />

      <AddTaskModal
        show={showAddTaskModal}
        candidateId={candidate._id}
        onCancel={() => setShowAddTaskModal(false)}
        onCreated={handleTaskCreated}
      />

      <AddLogModal
        show={showAddLogModal}
        candidateId={candidate._id}
        onCancel={() => setShowAddLogModal(false)}
        onCreated={handleLogCreated}
      />

      <AddAccountModal
        show={showCreateAccountModal}
        candidate={candidate}
        onCancel={() => setShowCreateAccountModal(false)}
        onSuccess={handleAccountCreated}
      />

      <ConfirmRemoveModal
        show={showConfirmRemoveAccount}
        validationValue={candidate.southEmail}
        content={
          <React.Fragment>
            <p>
              You are about to remove{' '}
              <b className="highlight">{candidate.southEmail}</b>.{' '}
              <b>This action cannot be undone.</b> Are you completely sure?
            </p>
            <br />
            <p>
              <b>Enter email address to confirm.</b>
            </p>
          </React.Fragment>
        }
        header="Hey... Removing this account?"
        onCancel={() => setShowConfirmRemoveAccount(false)}
        onConfirm={handleRemoveAccount}
      />
    </React.Fragment>
  )
}

export default CandidateActions
