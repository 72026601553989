import * as React from 'react'

import { useUser } from '../pages/session/hooks'
import POWERS from '../powers'

type Props = {
  powers: typeof POWERS[keyof typeof POWERS][]
  children: React.ReactNode
}

function RequirePowers({ powers, children }: Props) {
  const user = useUser()

  const userHasAllRequiredPowers = powers.every((power) =>
    user.powers.includes(power)
  )

  const userIsMaster = user.powers.includes('master')

  return userIsMaster || userHasAllRequiredPowers ? children : null
}

export default RequirePowers
