import React from 'react'

import { useContractsApi } from '../../store/mainContext'
import SidePanel, {
  // Divider,
  Section,
  // Subtitle,
} from '../sidepanel/sidePanel'
import TrFile from '../sidepanel/trFile'
import TrInput from '../sidepanel/trInput'
import TrContactSelect from '../sidepanel/trContactSelect'
import TrCheckbox from '../sidepanel/trCheckbox'

import ShowIf from '../showIf'
import Period from '../period'
import UserName from '../remoteValues/userName'
import DateFormat from '../dateFormat'
import CompanyName from '../remoteValues/companyName'
import { useUser } from '../../pages/session/hooks'
import POWERS from '../../powers'
// import { Button, Dropdown, Icon } from 'semantic-ui-react'
// import styled from 'styled-components'

// TODO unify this.
const terminationReasons = [
  {
    key: 'quit-better-offer',
    value: 'quit-better-offer',
    text: 'Quit. Better offer.',
  },
  {
    key: 'quit-unhappy',
    value: 'quit-unhappy',
    text: 'Quit. Unhappy.',
  },
  {
    key: 'fired-budget',
    value: 'fired-budget',
    text: 'Fired. Lack of Budget.',
  },
  {
    key: 'fired-performance',
    value: 'fired-performance',
    text: 'Fired. Performance issues.',
  },
  {
    key: 'fired-project-ended',
    value: 'fired-project-ended',
    text: 'Fired. Project finished.',
  },
]

const Contract = ({ contract, onContractChanged }) => {
  const api = useContractsApi()
  const user = useUser()

  if (!contract) {
    return null
  }

  return (
    <React.Fragment>
      <Section
        icon="folder open outline"
        title="Hire Information"
        subsection={true}
      >
        <SidePanel>
          <tbody>
            <tr>
              <th>Customer</th>
              <td>
                <a
                  rel="noreferrer"
                  href={`/companies/${contract.customerId}`}
                  target="_blank"
                >
                  <CompanyName id={contract.customerId} />
                </a>
              </td>
            </tr>

            <TrContactSelect
              injectedApi={api}
              key="hiringManagerId"
              name="hiringManagerId"
              label="Hiring Manager"
              value={contract.hiringManagerId}
              url={`contracts/${contract._id}`}
              onSuccess={onContractChanged}
              placeholder="Hiring Manager..."
              allowAdditions={true}
              render={(x) => x.name}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <tr>
              <th>Starting Date</th>
              <td>
                {new Date(contract.startingDate).toLocaleDateString('en-US')} (
                <Period date={contract.startingDate} />)
              </td>
            </tr>

            {contract.endDate && (
              <tr>
                <th>End Date</th>
                <td
                  style={{
                    color: contract.endDate > Date.now() && 'var(--green)',
                  }}
                >
                  {new Date(contract.endDate).toLocaleDateString('en-US')} (
                  <Period date={contract.endDate} />)
                </td>
              </tr>
            )}

            {contract.terminatedBy && (
              <TrContactSelect
                label="Terminated By"
                value={contract.terminatedBy}
                render={(x) => x.name}
                disabled={true}
              />
            )}

            {/* {contract.terminationReason &&} */}

            {contract.terminationReason && (
              <tr>
                <th>Termination Reason</th>
                <td>
                  {
                    terminationReasons.find(
                      (x) => x.value === contract.terminationReason
                    ).text
                  }
                </td>
              </tr>
            )}

            {contract.terminationDetails && (
              <tr>
                <th>Termination Details</th>
                <td>{contract.terminationDetails}</td>
              </tr>
            )}

            {contract.terminationFiledBy && (
              <tr>
                <th>Termination Filed By</th>
                <td>
                  <UserName id={contract.terminationFiledBy} />
                </td>
              </tr>
            )}

            {contract.terminationFiledOn && (
              <tr>
                <th>Termination Filed On</th>
                <td>
                  <DateFormat date={contract.terminationFiledOn} />
                </td>
              </tr>
            )}

            <TrFile
              injectedApi={api}
              key="offerLetter"
              name="offerLetter"
              label="Offer Letter"
              value={contract.offerLetter}
              url={`contracts/${contract._id}`}
              fileUrl={`cdn/offer-fhid92/offer-letter-${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <TrFile
              injectedApi={api}
              key="agreement"
              name="agreement"
              label="Agreement (ICA)"
              value={contract.agreement}
              url={`contracts/${contract._id}`}
              fileUrl={`cdn/offer-fhid92/agreement-${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <TrFile
              injectedApi={api}
              key="passport"
              name="passport"
              label="Id or Passport (Front)"
              value={contract.passport}
              url={`contracts/${contract._id}`}
              fileUrl={`cdn/offer-fhid92/passport-${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <TrFile
              injectedApi={api}
              key="passport2"
              name="passport2"
              label="Id or Passport (Back)"
              value={contract.passport2}
              url={`contracts/${contract._id}`}
              fileUrl={`cdn/offer-fhid92/passport2-${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <TrFile
              injectedApi={api}
              key="w8ben"
              name="w8ben"
              label="W-8 BEN"
              value={contract.w8ben}
              url={`contracts/${contract._id}`}
              fileUrl={`cdn/offer-fhid92/w8ben-${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            {contract.w8ben && (
              <TrFile
                injectedApi={api}
                key="w8ben1"
                name="w8ben1"
                label="W-8 BEN (Update 1)"
                value={contract.w8ben1}
                url={`contracts/${contract._id}`}
                fileUrl={`cdn/offer-fhid92/w8ben1-${contract._id}`}
                onSuccess={onContractChanged}
                disabled={!user.hasPower(POWERS.updateContract)}
              />
            )}
            {/* </SidePanel>
      </Section>

      <Section icon="file alternate outline" title="Organization Information">
        <SidePanel> */}
            <TrInput
              injectedApi={api}
              name="orgUnit"
              label="Org Unit"
              placeholder="Org unit..."
              value={contract.orgUnit}
              url={`contracts/${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <TrInput
              injectedApi={api}
              name="department"
              label="Department"
              placeholder="Department..."
              value={contract.department}
              url={`contracts/${contract._id}`}
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <TrContactSelect
              injectedApi={api}
              key="supervisorId"
              name="supervisorId"
              label="Supervisor"
              value={contract.supervisorId}
              url={`contracts/${contract._id}`}
              onSuccess={onContractChanged}
              placeholder="Supervisor..."
              allowAdditions={true}
              render={(x) => x.name}
              disabled={!user.hasPower(POWERS.updateContract)}
            />

            <ShowIf if={contract.supervisorId || contract.contact2}>
              <TrContactSelect
                injectedApi={api}
                key="contact2"
                name="contact2"
                label="2nd Contact"
                value={contract.contact2}
                url={`contracts/${contract._id}`}
                onSuccess={onContractChanged}
                placeholder="2nd Contact..."
                allowAdditions={true}
                render={(x) => x.name}
                disabled={!user.hasPower(POWERS.updateContract)}
              />
            </ShowIf>

            <ShowIf if={contract.contact2 || contract.contact3}>
              <TrContactSelect
                injectedApi={api}
                key="contact3"
                name="contact3"
                label="3er Contact"
                value={contract.contact3}
                url={`contracts/${contract._id}`}
                onSuccess={onContractChanged}
                placeholder="3er Contact..."
                allowAdditions={true}
                render={(x) => x.name}
                disabled={!user.hasPower(POWERS.updateContract)}
              />
            </ShowIf>

            <TrInput
              injectedApi={api}
              key="notes"
              name="notes"
              label="Notes"
              value={contract.notes}
              url={`contracts/${contract._id}`}
              onSuccess={onContractChanged}
              type="textarea"
              placeholder="Notes..."
              disabled={!user.hasPower(POWERS.updateContract)}
            />
          </tbody>
        </SidePanel>
      </Section>

      <Section
        icon="file alternate outline"
        title="Account Configuration"
        divider={false}
      >
        <SidePanel>
          <tbody>
            <TrCheckbox
              injectedApi={api}
              key="enableTaxAdvisorBenefit"
              name="enableTaxAdvisorBenefit"
              label="Tax Advisor Benefit"
              value={contract.enableTaxAdvisorBenefit}
              url={`contracts/${contract._id}`}
              help="If set this candidate will be able to request Tax Advisor Benefit"
              onSuccess={onContractChanged}
              disabled={!user.hasPower(POWERS.updateContract)}
            />
          </tbody>
        </SidePanel>
      </Section>
    </React.Fragment>
  )
}

export default Contract
