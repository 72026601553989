import React, { useState } from 'react'
import { Input, Button, Form } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'
import TrEditButton from './trEditButton'
import TrCopyButton from './trCopyButton'

const TrInput = ({
  name,
  label,
  placeholder,
  errors,
  value,
  onSuccess,
  onBlur,
  type,
  url,
  render,
  number,
  readOnly,
  disabled,
  // TODO: Alto conejo.
  injectedApi,
}) => {
  let api = useTalentApi()
  api = injectedApi || api

  const [showEdit, setShowEdit] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [, setCancelOnBlur] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [val, setVal] = useState(value)

  // default render
  render = render || ((x) => x)

  // useEffect(() => {
  //   setVal(value);
  // }, [value]);

  const handleSave = async () => {
    if (val === value) {
      handleCancel()
      return
    }

    setCancelOnBlur(false)
    setIsLoading(true)

    const obj = {}

    if (number) {
      obj[name] = Number(val)
    } else {
      obj[name] = val
    }

    await api.patch(url, obj)

    setIsLoading(false)
    setEditMode(false)
    setShowAdd(false)

    onSuccess && onSuccess(name, val)
  }

  const handleCancel = () => {
    setCancelOnBlur(false)
    setVal(value)
    setShowEdit(false)
    setEditMode(false)
    setIsLoading(false)
  }

  const handleKeyPress = (e) => {
    // const code = e.which

    if (e.which === 13) handleSave()
  }

  if (editMode) {
    return (
      <tr
      // className="solidBackground"
      // onBlur={(x) => !cancelOnBlur && handleCancel()}
      >
        <th>{label}</th>
        <td
          style={{
            paddingTop: 4,
            textAlign: `right`,
          }}
        >
          {type === 'textarea' && (
            <Form>
              <Form.TextArea
                name={name}
                placeholder={placeholder}
                error={
                  (errors || []).indexOf(name) !== -1 &&
                  'This field is required.'
                }
                value={val}
                onChange={(e) => setVal(e.target.value)}
                autoFocus={true}
                onBlur={onBlur}
                type={type}
                disabled={isLoading}
                onKeyPress={handleKeyPress}
                onFocus={(e) => e.target.select()}
                style={{ resize: `none`, fontSize: 14, height: 270 }}
              />
            </Form>
          )}

          {type !== 'textarea' && (
            <Input
              name={name}
              placeholder={placeholder}
              error={
                (errors || []).indexOf(name) !== -1 && 'This field is required.'
              }
              value={val}
              onChange={(e) => setVal(e.target.value)}
              autoFocus={true}
              onBlur={onBlur}
              type={type}
              style={{ width: '100%' }}
              disabled={isLoading}
              onKeyPress={handleKeyPress}
              onFocus={(e) => e.target.select()}
            />
          )}

          <div style={{ marginTop: 10 }}>
            <Button
              content="Cancel"
              size="tiny"
              onClick={handleCancel}
              style={{ padding: `9px 13px` }}
              disabled={isLoading}
              onMouseOver={(x) => setCancelOnBlur(true)}
              onMouseOut={(x) => setCancelOnBlur(false)}
            />

            <Button
              color="black"
              content="Save"
              size="tiny"
              style={{ padding: `9px 20px`, marginRight: 0 }}
              onClick={handleSave}
              loading={isLoading}
              onMouseOver={(x) => setCancelOnBlur(true)}
              onMouseOut={(x) => setCancelOnBlur(false)}
            />
          </div>
        </td>
      </tr>
    )
  }

  if (!val || val === '') {
    if (readOnly || disabled) {
      return (
        <tr>
          <th>{label}</th>
          <td></td>
        </tr>
      )
    }

    return (
      <tr
        onClick={() => setEditMode(true)}
        onMouseEnter={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
      >
        <th>{label}</th>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <td>{showAdd && <a>+ Add value</a>}&nbsp;</td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <tr
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <th>{label}</th>
        <td style={{ position: 'relative' }}>
          {render(val)}

          <TrCopyButton visible={showEdit} value={val} />
          {!readOnly && !disabled && (
            <TrEditButton
              onClick={() => setEditMode(true)}
              visible={showEdit}
            />
          )}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default TrInput
