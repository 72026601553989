import React from 'react'
import styled from 'styled-components'
import UserName from '../../remoteValues/userName'
// import Period from "../../period";
// import DateFormat from "../../dateFormat";
// import ShowIf from "../../showIf";

const Container = styled.div`
  position: relative;
  border-left: dotted 1px var(--border-grey);
  padding: 0px 20px 50px 30px;
  margin-left: 41px;
`

const ContainerFirstOne = styled.div`
  position: relative;
  padding: 0px 20px 50px 30px;
  margin-left: 41px;
`

const UserImage = styled.div`
  position: absolute;
  top: 0px;
  left: -21px;
  z-index: 2;
`

const Buttons = styled.div`
  position: absolute;
  right: 20px;
`

// const Date = styled.h3`
//   margin-top: 0px !important;
// `

const UserAvatar = styled.img`
  border-radius: 25px;
  height: 40px;
`

const Entry = ({ event, button, children, isFirstOne, userId, userImage }) => {
  const C = isFirstOne ? ContainerFirstOne : Container

  return (
    <C>
      <UserImage>
        {userImage ? (
          <UserAvatar src={userImage} />
        ) : (
          <UserName id={userId || event.createdBy} image height={40} />
        )}
      </UserImage>

      <Buttons>{button}</Buttons>

      {children}
    </C>
  )
}

export default Entry
