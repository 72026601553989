/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import DataTable from './dataTable'
import { useTalentApi } from '../../store/mainContext'
import PageLoader from '../pageLoader'
import styled from 'styled-components'

const NoResults = styled.div`
  height: 400px;
  font-size: 20px;
  text-align: center;
  padding: 200px;
`

const FooterLabel = styled.span`
  font-size: 14px;
`

const Footer = ({ showing, total, onShowMore, isLoading }) => {
  if (isLoading) {
    return (
      <FooterLabel>
        Showing <b>{showing}</b> of <b>{total}</b> - Loading...
      </FooterLabel>
    )
  }

  // eslint-disable-next-line eqeqeq
  if (Number(showing) == Number(total)) {
    return (
      <FooterLabel>
        <b>Showing {showing}</b> - That&apos;s all we have.
      </FooterLabel>
    )
  }

  return (
    <FooterLabel>
      Showing <b>{showing}</b> of <b>{total}</b> -{' '}
      <a href="#" onClick={onShowMore}>
        Show more...
      </a>
    </FooterLabel>
  )
}

const PagedTable = ({
  filter,
  collection = 'contacts',
  columns = [],
  pageSize = 30,
  defaultSort,
  onRowClick,
  joinCollection,
  joinFieldName,
  posthook,

  injectedApi,
}) => {
  const [data, setData] = useState([])
  const [total, setTotal] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [order, setOrder] = useState({
    sortBy: defaultSort || 'lastEvent',
    sortByDir: -1,
  })

  let api = useTalentApi()
  api = injectedApi || api

  const getData = React.useCallback(
    async (skip, limit) => {
      const headers = {
        'astor-limit': limit,
        'astor-skip': skip,
        'astor-sortby': order.sortBy,
        'astor-sortby-dir': order.sortByDir,
      }

      // eslint-disable-next-line no-shadow
      const { data, headers: resHeaders } = await api.get(`${collection}/pro`, {
        params: filter,
        headers,
      })

      setTotal(Number(resHeaders['astor-total']))

      if (joinCollection && joinFieldName) {
        const joinQuery = {}
        joinQuery._id = data
          .filter((x) => x[joinFieldName])
          .map((x) => x[joinFieldName])

        const { data: joinData } = await api.get(`${joinCollection}/in`, {
          params: joinQuery,
        })

        for (const index in data) {
          const item = data[index]
          const joinId = item[joinFieldName]

          if (!joinId) return

          item.joinedItem = joinData.find((x) => x._id === joinId)
        }
      }

      if (posthook) {
        const newData = await posthook(data)
        return newData
      }

      return data
    },
    [
      api,
      collection,
      filter,
      joinCollection,
      joinFieldName,
      order.sortBy,
      order.sortByDir,
      posthook,
    ]
  )

  useEffect(() => {
    setIsLoading(true)
    getData(0, pageSize)
      .then((d) => setData(d))
      .finally(() => setIsLoading(false))
  }, [filter, order, pageSize, getData])

  // Gets the next page
  const showMore = async (e) => {
    e.preventDefault()

    setIsLoadingMore(true)

    const newData = await getData(data.length, pageSize)

    setData([...newData, ...data])
    setIsLoadingMore(false)
  }

  const handleOrderChange = (field) => {
    if (order.sortBy === field) {
      return setOrder({
        sortBy: field,
        sortByDir: (order.sortByDir || 1) * -1,
      })
    }

    setOrder({
      sortBy: field,
      sortByDir: -1,
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (total === 0) {
    return <NoResults>Oops... No Match, sorry. :(</NoResults>
  }

  return (
    <DataTable>
      <thead>
        <tr>
          {columns.map((h) => (
            <DataTable.Th width={h.width} align={h.align} key={h.title}>
              {h.sortable !== false && (
                <a href="#" onClick={(x) => handleOrderChange(h.field)}>
                  {h.title}
                </a>
              )}
              {h.sortable === false && h.title}
            </DataTable.Th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((d) => (
          <tr key={d._id} onClick={() => onRowClick && onRowClick(d)}>
            {columns.map((h) => (
              <DataTable.Td key={h.field} align={h.align}>
                {h.render ? h.render(d[h.field]) : d[h.field]}
              </DataTable.Td>
            ))}
          </tr>
        ))}
      </tbody>

      <tfoot>
        <tr>
          <DataTable.Td colspan="11">
            <Footer
              showing={data.length}
              total={total}
              onShowMore={showMore}
              isLoading={isLoadingMore}
            />
          </DataTable.Td>
        </tr>
      </tfoot>
    </DataTable>
  )
}

export default PagedTable
