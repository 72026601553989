import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiError } from '../types'

const cacheable = [
  '/v1/talent/techs',
  '/v1/talent/profiles',
  '/v1/talent/seniority',
  '/v1/talent/englishLevels',
  '/v1/talent/badges',
  '/v1/talent/funnels',
  '/v1/talent/timezones',

  '/v2/auth/users',
]

const joinUrls = (baseURL: string, url: string) =>
  baseURL.replace(/\/+$/, '') + '/' + url.replace(/^\/+/, '')

const manageErrors =
  (setError: (newValue: ApiError | undefined) => void) =>
  (error: AxiosError) => {
    if (!error.response) {
      return setError({ message: 'ohoh... something happenend.' })
    }

    if (error.response.status === 500) {
      setError(error.response.data)
      return new Promise(() => {})
    }

    return Promise.reject(error)
  }

const buildCacheKey = (
  baseURL: string,
  url: string,
  params: Record<string, any>
) => {
  const fullUrl = joinUrls(baseURL, url)

  return `cache-v2::${fullUrl}::${JSON.stringify(params || {})}`
}

const getFromCache = (request: AxiosRequestConfig) => {
  // debugger;
  const { baseURL, url, method, params } = request

  if (method !== 'get') {
    return request
  }

  const key = buildCacheKey(baseURL || '', url || '', params)

  let dataFromCache: any

  try {
    dataFromCache = JSON.parse(window.localStorage.getItem(key) || '')
  } catch (e) {
    return request
  }

  // todo: add expiration
  if (!dataFromCache) {
    return request
  }

  // console.log(`Getting from cache: ${url}`)

  request.data = dataFromCache

  // Set the request adapter to send the cached response and prevent the request from actually running
  request.adapter = () => {
    return Promise.resolve({
      data: dataFromCache,
      status: 200,
      statusText: 'OK',
      headers: request.headers,
      config: request,
      request,
    })
  }

  return request
}

const setOnCache = (response: AxiosResponse) => {
  if (!response) {
    return
  }

  const { baseURL, method, url, params } = response.config

  const full = joinUrls(baseURL || '', url || '')
  const pathToRequest = new URL(full).pathname

  const isCacheable =
    method === 'get' && cacheable.some((x) => pathToRequest.indexOf(x) === 0)

  if (!isCacheable) {
    return response
  }

  const key = buildCacheKey(baseURL || '', url || '', params)

  window.localStorage.setItem(key, JSON.stringify(response.data))

  return response
}

const setupApi = (
  basePath: string,
  {
    setError,
  }: { setError: React.Dispatch<React.SetStateAction<ApiError | undefined>> }
) => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE}${basePath}`,
    responseType: 'json',
    headers: {
      Authorization: `${window.localStorage.getItem('token')}`,
    },
  })

  // On respose, handle errors
  api.interceptors.response.use((res) => res, manageErrors(setError))

  // On request, return the cached version, if any
  api.interceptors.request.use(getFromCache, (err) => Promise.reject(err))

  // On response, set or delete the cache
  api.interceptors.response.use(setOnCache, (err) => Promise.reject(err))

  return api
}

export { setupApi }
