import React, { useState } from 'react'
// import { Button, Icon } from "semantic-ui-react";
import styled from 'styled-components'
import { useContractsApi, useTalentApi } from '../../store/mainContext'
// import { useHistory } from "react-router-dom";

import PageHeader from '../../components/pageHeader/pageHeader'
import SearchCandidates from '../../components/search/searchCandidates'
// import CandidatesFilter from "../../components/filters/candidatesFilter";
import PagedTable from '../../components/dataTable/pagedTable'
// import RemoteValue from "../../components/remoteValues/remoteValue";
// import RemoteStars from "../../components/remoteValues/remoteStars";
// import Badges from "../../components/remoteValues/badges";
import Period from '../../components/period'
// import CompanyName from "../../components/remoteValues/companyName";
// import DateFormat from "../../components/dateFormat";
// import ContactName from "../../components/remoteValues/contactName";
// import { useUser } from '../session/hooks'
// import POWERS from "../../powers";
import ContractsFilter from '../../components/filters/contractsFilter'

const PageContent = styled.div`
  margin: 0px 50px 50px 50px;
`

// const TechLabel = styled.div`
//   border: solid 1px var(--border-grey);
//   background-color: var(--bg-grey);

//   padding: 3px 7px;
//   margin: 3px;
//   float: left;
//   border-radius: 4px;
//   font-size: 12px;
// `

const Contracts = () => {
  document.title = 'Contracts'

  // const user = useUser()

  const api = useTalentApi()
  const contractsApi = useContractsApi()

  const defaultFilter = {
    active: true,
  }

  const [filter, setFilter] = useState(defaultFilter)

  const getDataPosthook = async (contracts) => {
    // const { data: contracts } = await contractsApi.get(`contracts/in`, {
    //   params: {
    //     _id: deals.map((d) => d.contractId),
    //   },
    // });

    const [
      { data: deals },
      { data: companies },
      { data: contacts },
      { data: candidates },
    ] = await Promise.all([
      contractsApi.get(`deals/in`, {
        params: { contractId: contracts.map((x) => x._id) },
      }),

      api.get(`companies/in`, {
        params: { _id: contracts.map((x) => x.customerId) },
      }),

      api.get(`contacts/in`, {
        params: {
          _id: [
            ...contracts.map((x) => x.supervisorId),
            ...contracts.map((x) => x.hiringManagerId),
          ],
        },
      }),

      api.get(`candidates/in`, {
        params: { _id: contracts.map((x) => x.candidateId) },
      }),
    ])

    const rows = contracts.map((contract) => {
      const deal = deals.find((x) => x.contractId === contract._id)
      const customer = companies.find((x) => x._id === contract.customerId)
      const supervisor = contacts.find((x) => x._id === contract.supervisorId)
      const candidate = candidates.find((x) => x._id === contract.candidateId)
      const hiringManager = contacts.find(
        (x) => x._id === contract.hiringManagerId
      )

      return {
        position: deal.position,
        salary: deal.monthlySalary,

        team: customer ? customer.name : 'No Customer',

        supervisor: supervisor && supervisor.name,

        hiringManager: hiringManager && hiringManager.name,

        candidateId: candidate && candidate._id,
        name: candidate ? candidate.name : 'No Candidate',
        location: candidate ? candidate.location : 'No Candidate',

        startingDate: contract && contract.startingDate,
      }
    })

    return rows
  }

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: 250,
    },
    {
      title: 'Position',
      field: 'position',
      width: 250,
    },
    {
      title: 'Team',
      width: 200,
      field: 'team',
    },
    {
      title: 'Hiring Manager',
      width: 200,
      field: 'hiringManager',
    },
    {
      title: 'Supervisor',
      width: 200,
      field: 'supervisor',
    },
    {
      title: 'Fee',
      align: 'center',
      width: 100,
      field: 'salary',
      render: (salary) => <React.Fragment>$ {salary}</React.Fragment>,
    },
    {
      title: 'Starting Date',
      width: 250,
      field: 'startingDate',
      render: (startingDate) =>
        startingDate ? (
          <React.Fragment>
            {new Date(startingDate).toLocaleDateString()}
            &nbsp; (<Period date={startingDate} />)
          </React.Fragment>
        ) : (
          'No Date'
        ),
    },
    {
      title: 'Location',
      field: 'location',
    },
  ]

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Contracts', link: '/contracts' },
        ]}
        title="Contracts"
        sub="One screen to find them all."
        search={<SearchCandidates />}
        actions={
          <React.Fragment>
            {/* <Button basic onClick={handleAddCandidate}>
              <Icon name="add" /> Add Candidate
            </Button> */}
          </React.Fragment>
        }
      ></PageHeader>

      <PageContent>
        <ContractsFilter
          onFilterChange={setFilter}
          defaultFilter={defaultFilter}
        />

        <PagedTable
          injectedApi={contractsApi}
          collection="contracts"
          posthook={getDataPosthook}
          filter={filter}
          onRowClick={(row) =>
            window.open(`/candidates/${row.candidateId}`, '_blank')
          }
          defaultSort="team"
          columns={columns}
        />
      </PageContent>
    </React.Fragment>
  )
}

export default Contracts
