import React from 'react'
import RemoteValue from './remoteValue'

const CompanyName = ({ id, showLoading }) => (
  <RemoteValue
    key={id}
    id={id}
    collection="companies"
    predicate={(x) => x.name}
    showLoading={showLoading}
  />
)

export default CompanyName
