import React from 'react' // , { useEffect, useState }
import './datatable.css'

const DataTable = ({ children, style }) => {
  return (
    <table className="fx data-table" style={style}>
      {children}
    </table>
  )
}

DataTable.Th = ({ minWidth, width, children, align }) => (
  <th style={{ width, minWidth: width || minWidth, textAlign: align }}>
    {children}
  </th>
)

DataTable.Td = ({
  align,
  children,
  colspan,
  valign,
  paddingTop,
  paddingBottom,
}) => (
  <td
    colSpan={colspan}
    style={{
      textAlign: align,
      verticalAlign: valign,
      paddingTop,
      paddingBottom,
    }}
  >
    {children}
  </td>
)

DataTable.Th.displayName = 'DataTable.Th'
DataTable.Td.displayName = 'DataTable.Td'

export default DataTable
