import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuthApi } from '../../store/mainContext'

const UserImage = styled.img`
  border-radius: 25px;
`

const UserName = ({ id, short, image, height }) => {
  const api = useAuthApi()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()

  useEffect(() => {
    setLoading(true)
    api
      .get(`users/${id}`)
      .then(({ data }) => setValue(data))
      .catch(() => setValue({ name: 'Romebotie', image: '/img/bot.png' }))
      .finally(() => setLoading(false))
  }, [api, id])

  if (loading && image) {
    return (
      <UserImage style={{ height }} src="/img/bot.png" alt={'loading...'} />
    )
  }

  if (loading) {
    return <React.Fragment>(Trying to remember...)</React.Fragment>
  }

  if (image && value) {
    return <UserImage style={{ height }} src={value.image} alt={value.name} />
  }

  if (!value) return <React.Fragment></React.Fragment>

  if (short) return <React.Fragment>{value.name.split(' ')[0]}</React.Fragment>

  return <React.Fragment>{value.name}</React.Fragment>
}

export default UserName
