import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import RemoteValue from '../../components/remoteValues/remoteValue'
import { useTalentApi } from '../../store/mainContext'
// import ProfilesLabels from '../../components/remoteValues/remoteLabels'
// import RemoteLabels from '../../components/remoteValues/remoteLabels'
import PageLoader from '../../components/pageLoader'
import DateFormat from '../../components/dateFormat'
import Period from '../../components/period'
import UserName from '../../components/remoteValues/userName'
import ContactName from '../../components/remoteValues/contactName'
import CompanyName from '../../components/remoteValues/companyName'
import RequirePowers from '../../components/requirePowers'
import POWERS from '../../powers'
import NotFound from '../../components/notFound'

const Container = styled.div`
  min-width: 400px;
  max-width: 400px;
  width: 400px;

  min-height: 200vh;
  /* height: 100%; */
  border: none;
  border-left: dotted 1px var(--border-grey);
  /* overflow-y: scroll;

  position: sticky; */
  /* top: 0px; */
  /* box-shadow: 0px 0px 5px 0 rgb(34 36 38 / 15%); */
  padding: 40px;
  /* background-color: var(--bg-grey); */
`

const Card = styled.div`
  /* border: solid 1px var(--primary-light); */
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--primary-light);
  border: dotted 1px var(--border-primary);

  &:hover {
    cursor: pointer !important;
    filter: brightness(0.98);
    border: solid 1px var(--border-primary);
  }
`

// const Title = styled.h3`
//   color: var(--primary) !important;
//   font-weight: bold !important;
//   cursor: pointer;
//   margin: 0px;
// `

const Name = styled.h2`
  font-size: 17px;
  font-weight: bolder !important;
  margin-bottom: 5px !important;
`

const Line = styled.div`
  /* border-top: dotted 1px var(--border-grey); */
  padding-bottom: 7px;
`

// const Sub = styled.sub``

const SubmittedCandidates = ({ positionId }) => {
  const api = useTalentApi()

  const [isLoading, setIsLoading] = useState(true)
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (!positionId) {
      setIsLoading(false)
      return
    }

    api
      .get(`submissions`, {
        params: { positionId },
      })
      .then(({ data: allSubmissions }) =>
        api
          .get(`candidates/in`, {
            params: { _id: allSubmissions.map((x) => x.candidateId) },
          })
          .then(({ data: allCandidates }) =>
            setRows(
              allSubmissions.map((x) => ({
                ...x,
                candidate: allCandidates.find((c) => c._id === x.candidateId),
              }))
            )
          )
      )
      .finally(() => setIsLoading(false))
  }, [positionId, api])

  if (isLoading) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  if (rows.length === 0) {
    return (
      <Container>
        <NotFound
          style={{ marginTop: 200 }}
          message="No submissions yet"
          sub="C'mon! what are you waiting for?"
        />
      </Container>
    )
  }

  return (
    <Container>
      <h2>Submissions</h2>

      <p>Here you fill find candidates we have submitted for this position.</p>

      {rows.map((c) => (
        <Card
          key={c.candidateId}
          onClick={() => window.open(`/candidates/${c.candidateId}`)}
        >
          <h3>
            <b>
              {/* <DateFormat date={c.submittedOn} explain={false} /> */}
              SENT <Period date={c.submittedOn} />
            </b>{' '}
            BY <UserName id={c.submittedBy} />
          </h3>
          <Name>{c.candidate.name}</Name>

          <Line>
            <b>To:</b> <ContactName id={c.submittedTo} /> (
            <CompanyName id={c.customerId} />)
          </Line>
          <Line>
            <b>As:</b> <RemoteValue id={c.submittedAs} collection="seniority" />
          </Line>

          <Line>
            <b>YoE:</b> {c.candidate.yearsInTheIndustry} |{' '}
            {c.candidate.yearsInTheRole}
          </Line>

          <RequirePowers powers={[POWERS.customerSOWs]}>
            <Line>
              <b>Rate:</b> $ {c.rate}
            </Line>
          </RequirePowers>

          <Line>
            <b>Status:</b>{' '}
            <RemoteValue id={c.status} collection="submission-status" />
          </Line>

          {c.notes && <Line>{c.notes}</Line>}

          <Line>
            {c.submittedOn ? (
              <i>
                Sent on <DateFormat date={c.submittedOn} />
              </i>
            ) : (
              <i>Not sent yet.</i>
            )}
          </Line>
          {/* 
          {c.seniority && (
            <Line>
              <b>Level:</b>{" "}
              <RemoteValue id={c.seniority} collection="seniority" />
            </Line>
          )}

          {c.yearsInTheIndustry && (
            <Line>
              <b>Years of Experience: </b>
              {c.yearsInTheIndustry} | {c.yearsInTheRole}
            </Line>
          )}

          {c.location && (
            <Line>
              <b>Location: </b>
              {c.location}
            </Line>
          )}

          {c.location && (
            <Line>
              <b>English Level: </b>
              <RemoteValue id={c.englishLevel} collection="englishLevels" />
            </Line>
          )}

          {c.salary && (
            <Line>
              <b>Intended Salary : </b>$ {c.salary}
            </Line>
          )}

          {c.profiles && (
            <Line>
              <b>Profiles</b>
              <RemoteLabels ids={c.profiles} collection="profiles" />
            </Line>
          )}

          {c.mainTechs && (
            <Line>
              <b>Main Techs</b>
              <RemoteLabels ids={c.mainTechs} collection="techs" />
            </Line>
          )}

          <Line>
            Last touch on <DateFormat date={c.lastEvent} /> (
            <Period date={c.lastEvent} />)
          </Line> */}
        </Card>
      ))}
    </Container>
  )
}

export default SubmittedCandidates
