import React from 'react'

import SidePanel, { Section } from '../sidepanel/sidePanel'

import TrInput from '../sidepanel/trInput'
import TrDataSelect from '../sidepanel/trDataSelect'
import TrFile from '../sidepanel/trFile'
import TrUserSelect from '../sidepanel/trUserSelect'

import TrContactSelect from '../sidepanel/trContactSelect'
import RequirePowers from '../requirePowers'
import POWERS from '../../powers'
import { Icon } from 'semantic-ui-react'

const CompanySidePanel = ({ company, onChangeSuccess }) => (
  <React.Fragment>
    <Section icon="building outline" title="Company Information">
      <SidePanel>
        <TrInput
          key="name"
          name="name"
          label="Name"
          value={company.name}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Name..."
        />

        <TrInput
          key="sector"
          name="sector"
          label="Sector"
          value={company.sector}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Sector..."
        />

        <TrInput
          key="location"
          name="location"
          label="HQ Location"
          value={company.location}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Location..."
        />

        <TrInput
          key="address"
          name="address"
          label="Address"
          value={company.address}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Address..."
        />

        <TrInput
          key="website"
          name="website"
          label="Website"
          value={company.website}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Website..."
          render={(v) => (
            <a rel="noreferrer" href={v} target="_blank">
              {v}
            </a>
          )}
        />

        <TrInput
          key="linkedin"
          name="linkedin"
          label="LinkedIn"
          value={company.linkedin}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          render={(v) => (
            <a rel="noreferrer" href={v} target="_blank">
              {v}
            </a>
          )}
          placeholder="Linkedin Url..."
        />

        <TrContactSelect
          key="mainContactId"
          name="mainContactId"
          label="Main Contact"
          value={company.mainContactId}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Main contact..."
          allowAdditions={true}
          render={(x) => x.name}
        />

        <TrInput
          key="about"
          name="about"
          label="About"
          value={company.about}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          type="textarea"
          placeholder="About..."
        />

        <TrInput
          key="whatDoTheyDoExplained"
          name="whatDoTheyDoExplained"
          label="What do they do (Explained)"
          value={company.whatDoTheyDoExplained}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          type="textarea"
          placeholder="What do they do (explained)..."
        />

        <TrDataSelect
          key="timezoneId"
          name="timezoneId"
          label="Timezone"
          value={company.timezoneId}
          url={`companies/${company._id}`}
          dataCollection="timezones"
          onSuccess={onChangeSuccess}
          placeholder="Timezone..."
          orderBy={(x) => x.value}
        />

        <tr>
          <th>Added On</th>
          <td>
            {new Date(company.createdOn).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </td>
        </tr>
      </SidePanel>
    </Section>

    <RequirePowers powers={[POWERS.customerSOWs]}>
      <Section icon="handshake outline" title="Contract Information">
        <SidePanel>
          <TrFile
            key="msa"
            name="msa"
            label="Master Agreement"
            value={company.msa}
            url={`companies/${company._id}`}
            fileUrl={`cdn/msa/${company._id}`}
            onSuccess={onChangeSuccess}
          />

          <TrInput
            key="rateCard"
            name="rateCard"
            label="Rate Card"
            value={company.rateCard}
            url={`companies/${company._id}`}
            onSuccess={onChangeSuccess}
            render={(v) => (
              <a rel="noreferrer" href={v} target="_blank">
                <Icon name="file excel outline icon" /> Rate Card
              </a>
            )}
            placeholder="Rate Card Url..."
          />
        </SidePanel>
      </Section>
    </RequirePowers>

    <Section icon="settings" title="Account Configuration">
      <SidePanel>
        <TrUserSelect
          key="accountManagerId"
          name="accountManagerId"
          label="Account Manager"
          value={company.accountManagerId}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Account Manager..."
          allowAdditions={true}
        />

        <TrUserSelect
          key="adminManagerId"
          name="adminManagerId"
          label="Administrative Manager"
          value={company.adminManagerId}
          url={`companies/${company._id}`}
          onSuccess={onChangeSuccess}
          placeholder="Admin manager..."
          allowAdditions={true}
        />

        <RequirePowers powers={[POWERS.customerSOWs]}>
          <TrInput
            key="idealDGM"
            name="idealDGM"
            label="Ideal DGM"
            value={company.idealDGM}
            url={`companies/${company._id}`}
            onSuccess={onChangeSuccess}
            placeholder="Dev Gross Margin..."
            render={(v) => `$ ${v}`}
            number={true}
          />
        </RequirePowers>
      </SidePanel>
    </Section>
  </React.Fragment>
)

export default CompanySidePanel
