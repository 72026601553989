import React, { useEffect, useState } from 'react'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'

const BgButton = styled.button`
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
  border: none;
  background: none;
`

const NoActive = styled.div`
  color: #dedede;
  &:hover {
    filter: brightness(0.8);
  }
`

const Active = styled.div`
  filter: brightness(1.1);
  &:hover {
    filter: brightness(1.1);
  }
`

const BgIcon = styled.i`
  font-size: 24px;
`

const BudgetButton = ({ badge, isActive, onClick }) => {
  if (isActive) {
    return (
      <BgButton onClick={onClick}>
        <Active>
          <Popup
            trigger={
              <BgIcon className={badge.icon} style={{ color: badge.color }} />
            }
            content={badge.name}
            position="top left"
          />
        </Active>
      </BgButton>
    )
  }

  return (
    <BgButton onClick={onClick}>
      <NoActive>
        <Popup
          trigger={<BgIcon className={badge.icon} />}
          content={badge.name}
          position="top left"
        />{' '}
      </NoActive>
    </BgButton>
  )
}

const TrBadges = ({ name, value, url, onSuccess }) => {
  const api = useTalentApi()

  const [badges, setBadges] = useState([])

  useEffect(() => {
    api.get('badges').then(({ data }) => setBadges(data))
  }, [api])

  const toogleBadge = async (badge) => {
    const isThere = (value || []).indexOf(badge._id) !== -1

    const arr = isThere
      ? value.filter((id) => {
          return id !== badge._id
        })
      : [badge._id, ...(value || [])]

    const obj = {}
    obj[name] = arr

    await api.patch(url, obj)

    onSuccess && onSuccess(name, arr)
  }

  return (
    <tr>
      <th>Badges</th>
      <td>
        {badges
          .sort((a, b) => a.order - b.order)
          .map((x) => (
            <BudgetButton
              key={x._id}
              badge={x}
              isActive={(value || []).indexOf(x._id) !== -1}
              onClick={() => toogleBadge(x)}
            />
          ))}
      </td>
    </tr>
  )
}

export default TrBadges
