import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Breadcrumb } from 'semantic-ui-react'
import styled from 'styled-components'
import { FlexDiv } from '../html'
import ShowIf from '../showIf'
import './pageHeader.css'

const Sub = styled.p`
  color: #666;
`
const Title = styled.h1`
  margin-bottom: 5px;
`

const PageHeader = ({
  title,
  filters,
  actions,
  search,
  views,
  onViewChange,
  breadcrumb,
  footer,
  sub,
}) => {
  const [activeView, setActiveView] = useState()

  useEffect(() => {
    setActiveView((views || [])[0])
  }, [views])

  const handleViewClick = (view) => {
    setActiveView(view)
    onViewChange && onViewChange(view)
  }

  const Detail = styled.span`
    color: grey;
    font-weight: normal;
  `

  return (
    <React.Fragment>
      <ShowIf if={breadcrumb}>
        <div style={{ padding: `10px 40px` }}>
          <Breadcrumb style={{}}>
            {(breadcrumb || []).map((x, index) => {
              if (index === breadcrumb.length - 1) {
                return (
                  <Breadcrumb.Section active key={x.text}>
                    {x.text} {x.sub && <Detail>({x.sub})</Detail>}
                  </Breadcrumb.Section>
                )
              }

              return (
                <React.Fragment key={x.text}>
                  <Breadcrumb.Section>
                    <Link to={x.link}>{x.text}</Link>
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron" />
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </div>
      </ShowIf>

      {title && (
        <div className="fx pageHeader">
          <FlexDiv
            justifyContent="space-between"
            style={{ margin: `20px 50px 20px 50px` }}
          >
            <div>
              <Title>{title}</Title>
              <Sub>{sub}</Sub>
            </div>

            <div className="fx items">{filters}</div>

            <FlexDiv>
              <div className="fx items">{search}</div>
              <div className="fx items">{actions}</div>
            </FlexDiv>
          </FlexDiv>

          {views && (
            <Menu secondary pointing style={{ marginTop: -20 }}>
              <Menu.Item name=" " key={views} />
              {(views || []).map((x) => (
                <Menu.Item
                  key={x}
                  name={x}
                  active={x === activeView}
                  onClick={() => handleViewClick(x)}
                />
              ))}
            </Menu>
          )}

          {footer}
        </div>
      )}
    </React.Fragment>
  )
}

export default PageHeader
