import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router'

import { useTalentApi } from '../../store/mainContext'
import SearchCandidates from '../../components/search/searchCandidates'
import FunnelPage from './funnelPage'
import styled from 'styled-components'
import ContactName from '../../components/remoteValues/contactName'

const Sub = styled.div`
  margin-top: 7px;
  font-size: 14px;
  color: #747474;
  line-height: 20px;
`

const CandidatesFunnel = (props) => {
  const history = useHistory()
  const api = useTalentApi()

  if (!props) return null

  const handleAdd = async ({ status }) => {
    const { data } = await api.post('candidates', {
      name: 'New Candidate',
    })

    history.push(`/candidates/${data._id}`)
  }

  const getData = async ({ statuses }) => {
    // 1. Get candidates
    const { data: candidates } = await api.get(`candidates/in`, {
      params: { status: statuses },
    })

    const [{ data: tasks }, { data: pos }] = await Promise.all([
      // 2. Get Tasks for those candidates
      api.get(`tasks/in`, {
        params: { relatedId: candidates.map((x) => x._id) },
      }),

      // 3. Get Positions for those candidates
      api.get(`positions/in`, {
        params: { _id: candidates.map((x) => x.positionId) },
      }),
    ])

    // 4. Get Companies for those positions
    const { data: comp } = await api.get(`companies/in`, {
      params: { _id: pos.map((x) => x.companyId) },
    })

    // Put everything together
    return candidates.map((c) => {
      c.tasks = tasks.filter((x) => x.relatedId === c._id && !x.isDone)
      c.relatedPosition = pos.find((p) => p._id === c.positionId)
      c.company =
        c.relatedPosition &&
        comp.find((company) => company._id === c.relatedPosition.companyId)
      return c
    })
  }

  return (
    <FunnelPage
      collectionName="candidates"
      toolBar={
        <React.Fragment>
          <SearchCandidates />
          <Button basic onClick={handleAdd} style={{ marginLeft: 10 }}>
            <Icon name="add" /> Add Candidate
          </Button>
        </React.Fragment>
      }
      CardContent={CandidateCard}
      getData={getData}
      {...props}
    />
  )
}

const CandidateCard = ({ card }) => (
  <React.Fragment>
    {card.relatedPosition ? (
      <Sub>
        {card.relatedPosition.number} - {card.relatedPosition.title} <br />
        <ContactName
          id={card.relatedPosition.hiringManagerId}
          showLoading={true}
        />
      </Sub>
    ) : (
      <Sub>No position related yet.</Sub>
    )}

    {card.company && (
      <Sub>
        <b>{card.company.name}</b>
      </Sub>
    )}
  </React.Fragment>
)

export default CandidatesFunnel
