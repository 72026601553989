import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import React from 'react'

import './variables.css'
import './index.css'
import 'semantic-ui-css/semantic.min.css'

import App from './App'

import { Provider as SessionProvider } from './pages/session/sessionContext'
import { Provider as MainProvider } from './store/mainContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MainProvider>
    <SessionProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SessionProvider>
  </MainProvider>
)
