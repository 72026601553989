import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'semantic-ui-react'

const SimpleFilter = ({ name, options, value, disabled, onFilterChange }) => {
  const [selected, setSelected] = useState({})

  useEffect(() => {
    setSelected(value || options[0])
  }, [value, options])

  let trigger = (
    <Button size="tiny" basic>
      <b>{selected.text}</b>
    </Button>
  )

  if (selected.key !== 0) {
    trigger = (
      <Button size="tiny" color="black">
        <b>{selected.text}</b>
      </Button>
    )
  }

  const handleChange = (e, selection) => {
    const selectedOption = options.find((x) => x.value === selection.value)
    setSelected(selectedOption)

    const newFilter = {}

    if (!selection.value || selection.value.length === 0) {
      newFilter[name] = null
    } else {
      newFilter[name] = Array.isArray(selection.value)
        ? { $in: selection.value }
        : selection.value
    }

    onFilterChange && onFilterChange(newFilter, name, selection.value)
  }

  return (
    <Dropdown
      disabled={disabled}
      position={100}
      icon={null}
      trigger={trigger}
      options={options}
      onChange={handleChange}
      closeOnChange={true}
      value={selected}
    />
  )
}

export default SimpleFilter
