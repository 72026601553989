import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'
import Period from '../period'

import StatusSelector from './statusSelector'

const Container = styled.div`
  margin: 00px 30px 30px 30px;
  border: dotted 1px #e5e5c3;
  background-color: beige;
  border-radius: 4px;
  &:hover {
    border: solid 1px #e5e5c3;
    /* box-shadow: var(--active-shadow); */
  }
`

const Content = styled.div`
  padding: 10px;
  text-align: center;
  cursor: pointer;
  /* background-color: var(--primary-light); */

  /* box-shadow: inset 0 2px 8px 0px rgba(0,0,0,0.2); */

  color: #585858;

  position: relative;
`

const FunnelBar = ({ entity, collection, onSuccess }) => {
  const api = useTalentApi()

  const [isLoading, setIsLoading] = useState(true)

  const [funnel, setFunnel] = useState()
  const [funnels, setFunnels] = useState()
  const [column, setColumn] = useState()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    api
      .get('funnels', { params: { collection } })
      .then(({ data }) => {
        setFunnels(data)
        if (!entity.status) return

        const funnelName = entity.status.split('.')[0]
        const currentFunnel = data.find((f) => f.name === funnelName)

        setFunnel(currentFunnel)
        setColumn(currentFunnel.columns.find((x) => x.id === entity.status))
      })
      .finally(() => setIsLoading(false))
  }, [api, collection, entity.status])

  const handleRemove = () => {
    api
      .delete(`${collection}/${entity._id}/status`)
      .then(() => setIsOpen(false))
      .then(() => onSuccess('status', null))
  }

  const handleSave = async (newColumn) => {
    await api.patch(`${collection}/${entity._id}`, {
      status: newColumn.id,
    })

    setIsOpen(false)

    onSuccess('status', newColumn.id)
  }

  if (isLoading) {
    return <Content>Loading...</Content>
  }

  if (isOpen) {
    return (
      <Container>
        <Content>
          <StatusSelector
            funnels={funnels}
            value={column}
            onCancel={() => setIsOpen(false)}
            onRemove={handleRemove}
            onSave={handleSave}
          />
        </Content>
      </Container>
    )
  }

  if (!funnel) {
    return (
      <Container>
        <Content onClick={() => setIsOpen(!isOpen)}>
          <span style={{ color: '#555' }}>
            <Icon name="arrow right" /> Send to a Stage
          </span>
        </Content>
      </Container>
    )
  }

  return (
    <Container>
      <Content onClick={() => setIsOpen(!isOpen)}>
        {funnel.title} <Icon name="angle right" />
        <b> {column.title}</b>
        <div style={{ marginTop: 3 }}>
          <Period date={entity.lastStatusUpdate} />
        </div>
      </Content>
    </Container>
  )
}

export default FunnelBar
