import React, { useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import {
  Button,
  // Checkbox,
  Confirm,
  Dropdown,
  DropdownItem,
  Icon,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useTalentApi } from '../../store/mainContext'
import DateFormat from '../dateFormat'
import CheckButton from '../form/checkButton'
import DataSelect from '../form/dataSelect'
import DateSelect from '../form/dateSelect'
import UserSelect from '../form/userSelect'
import Period from '../period'
import UserName from '../remoteValues/userName'
import FormField from '../form/formField'
import Check from '../form/check'
import { isToday } from '../../utils'

const Content = styled.div`
  position: relative;
  border: dotted 1px var(--border-grey);
  padding: 20px;
  border-radius: 4px;
  background-color: #fcfcfc;
  margin-bottom: 20px;
`

const ContentExpired = styled.div`
  position: relative;

  padding: 20px;
  border-radius: 4px;
  background-color: #fffcfc;
  border: dotted 1px #e1bfbf;
  margin-bottom: 20px;

  h2,
  h3 b {
    color: var(--red) !important;
  }
`

const ContentToday = styled.div`
  position: relative;

  padding: 20px;
  border-radius: 4px;
  border: dotted 1px var(--border-grey);
  background-color: #fcfcfc;

  /* border: dotted 1px var(--border-primary);
  background-color: var(--primary-light); */

  margin-bottom: 20px;

  h2,
  h3 b {
    color: var(--primary) !important;
  }
`

const ContentDone = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 4px;

  border: dotted 1px var(--border-primary);
  background-color: var(--primary-light);

  margin-bottom: 20px;

  h2,
  h3 b {
    color: var(--primary) !important;
  }
`

const ContentFulfill = styled.div`
  position: relative;
  border: dotted 1px var(--border-grey);
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: var(--active-shadow);
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  align-items: flex-start;
  cursor: pointer;
`

const Title = styled.header`
  margin: 0px;
  width: 100%;
  padding-left: 15px;
  font-size: 20px !important;
`

const Details = styled.div`
  margin-top: 20px;
  font-size: 16px !important;
  line-height: 1.4em;
  padding: 0px 5px;
`

const Fields = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
`

const Split = styled.div`
  border-top: dotted 1px var(--border-grey);
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
`

const Sub = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 20px;
`

const DateLabel = styled.h3`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: #666 !important;
  display: flex;
  align-items: center;
`

const Menu = styled.div`
  position: absolute;
  font-size: 15px;
  top: 0px;
  right: 0px;
  z-index: 90;
`

const ButtonBar = styled.div`
  margin-top: 20px;
  text-align: right;
`

const Editor = styled(ContentEditable)`
  width: 100%;

  &:focus {
    outline: none;
  }
`
const ViewMoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
`

// const SmallContent = styled.div``

// TODO: Este componente esta malisimo, quedo muy complejo, refactorizar.
const PendingTask = ({
  task,
  isFirstOne,
  disabled,
  onChangeSuccess,
  onDone,
}) => {
  const editor = useRef(null)
  const api = useTalentApi()

  const [currentTask, setCurrentTask] = useState({ ...task })

  const [isCollapsed, setIsCollapsed] = useState(true)
  const [editMode, setEditMode] = useState(false)

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [markingDone, setMarkingDone] = useState(false)

  const [fullfilMode, setFulfillMode] = useState(false)

  const collapse = !editMode && !fullfilMode && isCollapsed

  let Container = Content

  if (isToday(task.dueDate)) {
    Container = ContentToday
  } else if (task.dueDate < Date.now()) {
    Container = ContentExpired
  }
  if (fullfilMode) {
    Container = ContentFulfill
  }

  if (markingDone) {
    Container = ContentDone
  }

  useEffect(() => {
    const doneNoteInLocalStorage = window.localStorage.getItem(
      `task-doneNote-${currentTask._id}`
    )

    if (doneNoteInLocalStorage) {
      setCurrentTask({ ...task, doneNote: doneNoteInLocalStorage })
      return
    }

    setCurrentTask({ ...task })
  }, [task, currentTask._id])

  useEffect(() => {
    if (editor && editor.current) {
      editor.current.el.current.focus()
    }
  }, [editMode, fullfilMode])

  useEffect(() => {
    currentTask.doneNote &&
      window.localStorage.setItem(
        `task-doneNote-${currentTask._id}`,
        currentTask.doneNote
      )
  }, [currentTask.doneNote, currentTask._id])

  const handleCancel = () => {
    setCurrentTask({ ...task })
    setEditMode(false)
    setFulfillMode(false)
  }

  const handleSave = async () => {
    setIsSaving(true)

    const update = {}

    const fields = [
      'subject',
      'details',
      'relatedId',
      'assignedToId',
      'dueDate',
      'requireDoneNote',
    ]

    for (const i in fields) {
      const field = fields[i]

      if (task[field] !== currentTask[field]) {
        update[field] = currentTask[field]
      }
    }

    if (Object.keys(update).length > 0) {
      await api.patch(`tasks/${task._id}`, update)
    }

    onChangeSuccess && onChangeSuccess()

    setIsSaving(false)
    setEditMode(false)
  }

  const handleConfirmRemove = async () => {
    setIsRemoving(true)
    await api.delete(`tasks/${task._id}`)

    onChangeSuccess && onChangeSuccess()

    setIsRemoving(false)
    setShowConfirmModal(false)
  }

  const timeout = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay))
  }

  const handleMarkAsDone = async () => {
    if (currentTask.requireDoneNote) {
      setFulfillMode(!fullfilMode)
      return
    }

    await markAsDone()
  }

  const handleMarkAsDoneWithNote = async () => await markAsDone()

  const markAsDone = async () => {
    setMarkingDone(true)

    setCurrentTask({ ...currentTask, isDone: true })
    await api.patch(`tasks/${currentTask._id}`, {
      isDone: true,
      doneNote: currentTask.doneNote,
    })

    await timeout(500)

    onDone && onDone()
  }

  return (
    <React.Fragment>
      <Container>
        <Header onClick={() => !editMode && setIsCollapsed(!isCollapsed)}>
          <div style={{ marginTop: 3 }}>
            <CheckButton
              value={currentTask.isDone || fullfilMode}
              onChange={handleMarkAsDone}
              disabled={markingDone || editMode}
            />
          </div>

          <Title>
            <div style={{ display: 'flex' }}>
              {/* <div>Task:&nbsp;</div>  */}

              <Editor
                html={currentTask.subject}
                disabled={!editMode}
                onChange={(e) =>
                  setCurrentTask({ ...currentTask, subject: e.target.value })
                }
                name="subject"
              />
            </div>

            {!editMode && (
              <DateLabel>
                <b>
                  <Period date={currentTask.dueDate} />
                </b>
                &nbsp;-&nbsp;
                <DateFormat date={currentTask.dueDate} />
                &nbsp;-&nbsp;
                <UserName id={currentTask.assignedToId} short height={20} />
              </DateLabel>
            )}
          </Title>

          {!disabled && !editMode && !fullfilMode && (
            <Menu>
              <ViewMoreButton>
                {collapse ? (
                  <Icon name="chevron down" />
                ) : (
                  <Icon name="chevron up" />
                )}
              </ViewMoreButton>

              <Dropdown icon="ellipsis horizontal" direction="left">
                <Dropdown.Menu>
                  <DropdownItem onClick={() => setEditMode(true)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem onClick={() => setShowConfirmModal(true)}>
                    <span style={{ color: 'red' }}>Remove</span>
                  </DropdownItem>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          )}
        </Header>

        {!collapse && (
          <React.Fragment>
            <Details>
              <Editor
                name="details"
                html={currentTask.details || ''}
                disabled={!editMode}
                ref={editor}
                onChange={(e) =>
                  setCurrentTask({ ...currentTask, details: e.target.value })
                }
                style={{ minHeight: editMode && 100 }}
              />

              {!editMode && (
                <Sub>
                  Task created by{' '}
                  <b>
                    <UserName id={currentTask.createdBy} />
                  </b>{' '}
                  on <DateFormat date={currentTask.createdOn} hours />
                </Sub>
              )}
            </Details>

            {editMode && (
              <React.Fragment>
                <Split />
                <div style={{ marginTop: 20 }}></div>

                {currentTask.relatedCollection === 'candidates' && (
                  <Fields>
                    <FormField label="Candidate">
                      <DataSelect
                        name="relatedId"
                        search
                        placeholder="Candidate..."
                        url="candidates"
                        fluid={true}
                        onChange={(e, target) =>
                          setCurrentTask({
                            ...currentTask,
                            relatedId: target.value,
                          })
                        }
                        render={(x) => `${x.name} (${x.email})`}
                        value={currentTask.relatedId}
                        key={currentTask.relatedId}
                      />
                    </FormField>
                  </Fields>
                )}

                <Fields>
                  <FormField label="Assigned To">
                    <UserSelect
                      name="assignedToId"
                      search
                      placeholder="Assigned To..."
                      fluid={true}
                      onChange={(e, target) =>
                        setCurrentTask({
                          ...currentTask,
                          assignedToId: target.value,
                        })
                      }
                      render={(x) => x.name}
                      value={currentTask.assignedToId}
                      key={currentTask.assignedToId}
                    />
                  </FormField>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FormField label="Due Date *">
                    <DateSelect
                      name="dueDate"
                      pointing="top left"
                      fluid
                      onChange={(e, target) =>
                        setCurrentTask({
                          ...currentTask,
                          dueDate: target.value,
                        })
                      }
                      value={
                        currentTask.dueDate && new Date(currentTask.dueDate)
                      }
                      autocomplete="off"
                    />
                  </FormField>
                </Fields>

                <Fields>
                  <Check
                    text="Require note for completition"
                    value={currentTask.requireDoneNote}
                    onChange={() =>
                      setCurrentTask({
                        ...currentTask,
                        requireDoneNote: !currentTask.requireDoneNote,
                      })
                    }
                  />

                  <ButtonBar>
                    <Button basic disabled={isSaving} onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      color="black"
                      onClick={handleSave}
                      loading={isSaving}
                      disabled={
                        !currentTask.dueDate || !currentTask.assignedToId
                      }
                    >
                      <Icon name="save"></Icon>Save
                    </Button>
                  </ButtonBar>
                </Fields>
              </React.Fragment>
            )}

            {fullfilMode && (
              <React.Fragment>
                <Split />
                <Details>
                  <h3>
                    <Icon name="clipboard outline" />
                    <b>Done Notes</b>
                  </h3>
                  <Editor
                    name="doneNote"
                    html={currentTask.doneNote || ''}
                    ref={editor}
                    onChange={(e) =>
                      setCurrentTask({
                        ...currentTask,
                        doneNote: e.target.value,
                      })
                    }
                    placeholder="Say something..."
                    style={{
                      fontSize: 17,
                      minHeight: fullfilMode && !markingDone && 200,
                    }}
                    disabled={markingDone}
                  />
                </Details>

                {!markingDone && (
                  <ButtonBar>
                    <Button basic disabled={markingDone} onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      color="black"
                      onClick={handleMarkAsDoneWithNote}
                      loading={markingDone}
                      disabled={
                        !currentTask.dueDate ||
                        !currentTask.assignedToId ||
                        !currentTask.doneNote ||
                        currentTask.doneNote.length < 4
                      }
                    >
                      <Icon name="check"></Icon>Save As Done
                    </Button>
                  </ButtonBar>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>

      <Confirm
        open={showConfirmModal}
        closeOnDimmerClick={true}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmRemove}
        content={
          <div style={{ padding: 20 }}>
            <p>
              You are about to remove this task.{' '}
              <b>This action cannot be undone.</b>
            </p>
            <p>Do you want to continue?</p>
          </div>
        }
        header="Hey... removing the task?"
        confirmButton={
          <Button primary={false} color="red" loading={isRemoving}>
            Sure, remove.
          </Button>
        }
      />
    </React.Fragment>
  )
}

export default PendingTask
