import React from 'react'

import styled from 'styled-components'
import Period from '../period'
import SearchBox from './searchBox'

const CandidateName = styled.p`
  font-size: 1.3em;
  margin-bottom: 3px;
  font-weight: bold;
`

const CandidateSub = styled.div`
  color: #61605f;

  p {
    line-height: 22px;
  }
`

const SearchCandidates = () => (
  <SearchBox
    collection="candidates"
    orderBy="lastEvent"
    fields={['name', 'linkedin', 'email']}
    render={({ name, email, linkedin, location, lastEvent, referal }) => (
      <React.Fragment>
        <CandidateName>{name}</CandidateName>
        <CandidateSub>
          <p>
            {email || 'No email.'}
            <br />
            {linkedin || 'No linkedin.'}
            <br />
            {location || 'No location.'}
          </p>

          <p>
            {referal && (
              <React.Fragment>
                <b>Referal:</b> {referal} <br />
              </React.Fragment>
            )}
            <b>Last Touch:</b> <Period date={lastEvent} />
          </p>
        </CandidateSub>
      </React.Fragment>
    )}
  />
)

export default SearchCandidates
