import { io } from 'socket.io-client'

export const socket = io(
  process.env.REACT_APP_TALENT_WS_DOMAIN || 'ws://localhost:8080/',
  {
    path: '/v1/talent/ws',
    transports: ['websocket'],
    reconnection: process.env.REACT_APP_TALENT_WS_RECONNECTION !== 'false',
  }
)
