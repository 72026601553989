const getDiffInDays = (from, to) => {
  const fromDate = new Date(new Date(from).toLocaleDateString('en-US'))
  const toDate = new Date(new Date(to).toLocaleDateString('en-US'))

  const result = (toDate - fromDate) / 1000 / 60 / 60 / 24

  return result
}

const isToday = (value) => {
  return getDiffInDays(new Date(), value) === 0
}

const isYesterday = (value) => {
  return getDiffInDays(new Date(), value) === -1
}

const isTomorrow = (value) => {
  return getDiffInDays(new Date(), value) === 1
}

const isBeforeToday = (value) => {
  return getDiffInDays(new Date(), value) < 0
}

const isAfterToday = (value) => {
  return getDiffInDays(new Date(), value) > 0
}

const timeout = (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

export {
  getDiffInDays,
  isToday,
  isYesterday,
  isTomorrow,
  isBeforeToday,
  isAfterToday,
  timeout,
}
