import React from 'react'

export const Header = ({ head, sub, title }) => (
  <div style={{ marginTop: 0, marginBottom: 20 }}>
    {head && <h3 style={{ marginTop: 0, marginBottom: 5 }}>{head}</h3>}
    <h2 style={{ margin: 0, textTransform: 'capitalize' }}>{title}</h2>
    {sub && (
      <sub
        style={{
          fontSize: 15,
          letterSpacing: 0.5,
        }}
      >
        {sub}
      </sub>
    )}
  </div>
)

export const FlexDiv = ({
  children,
  direction,
  justifyContent,
  style,
  className,
}) => (
  <div
    style={{
      ...style,
      display: 'flex',
      flexDirection: direction,
      justifyContent: justifyContent || 'space-between',
    }}
    className={className}
  >
    {children}
  </div>
)
