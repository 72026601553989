import React from 'react'
import GoogleLogin from 'react-google-login'
import './login.css'
import { Button } from 'semantic-ui-react'
import { useSessionActions, useSessionState } from '../hooks'
import PageLoader from '../../../components/pageLoader'
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
`

const Login = () => {
  const { handleGoogleResponse } = useSessionActions()
  const { error, loaded } = useSessionState()

  if (!loaded) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  if (error) {
    return (
      <section className="login">
        <div>
          <img src="/img/isologo.png" alt="southteams" />
        </div>
        <p className="error">{error.message}</p>
      </section>
    )
  }

  return (
    <section className="login">
      <div>
        <img src="/img/isologo.png" alt="southteams" />
      </div>
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Login"
          onSuccess={handleGoogleResponse}
          onFailure={handleGoogleResponse}
          cookiePolicy={'single_host_origin'}
          // scope={"https://mail.google.com/"}

          // accessType="offline"
          // responseType="code"
          // approvalPrompt="force"

          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              color="red"
            >
              Login with Google
            </Button>
          )}
        />
      </div>
    </section>
  )
}

export default Login
