import React, { useEffect, useState } from 'react'
import { useContractsApi } from '../../store/mainContext'
import { Divider, Section } from '../sidepanel/sidePanel'

import AddContractModal from '../modals/addContractModal'
import RequirePowers from '../requirePowers'

import Deal from './deal'
import POWERS from '../../powers'

import { useUser } from '../../pages/session/hooks'
import Contract from './contract'
import ContractsHistory from './contractsHistory'

import ContractsMenu from './contractsMenu'
import SideAddButton from '../sidepanel/sideAddButton'

const Contracts = ({ candidate, onSelectedContractChange }) => {
  const api = useContractsApi()

  const user = useUser()

  const [isLoading, setIsLoading] = useState(true)

  const [contracts, setContracts] = useState([])
  const [deals, setDeals] = useState([])

  const [selectedContract, setSelectedContract] = useState(null)
  const [selectedDeal, setSelectedDeal] = useState(null)
  const [selectedCustomerSOW, setSelectedCustomerSOW] = useState(null)

  const [showAddContractModal, setShowAddContractModal] = useState(false)

  const [showHistory, setShowHistory] = useState(false)

  const refresh = React.useCallback(async () => {
    // 1. Get All Contracts
    const { data: allContracts } = await api.get(`/${candidate._id}/contracts`)

    if (allContracts.length === 0) {
      setIsLoading(false)
      return
    }

    // 2. Get All Deals for those contracts
    const { data: allDeals } = await api.get(`/deals/in`, {
      params: { contractId: allContracts.map((x) => x._id) },
    })

    const orderedContracts = allContracts.sort(
      (a, b) => b.startingDate - a.startingDate
    )

    setContracts(orderedContracts)
    setDeals(allDeals.sort((a, b) => b.effectiveDate - a.effectiveDate))

    // Selects the one with no endDate (i mean, the active one) or the last one.
    const selContract =
      orderedContracts.find((x) => !x.endDate) || orderedContracts[0]

    // Finds the last deal of the seleced contract.
    const selDeal = allDeals
      .filter((d) => d.contractId === selContract._id)
      .reduce((prev, curr) => {
        return prev.effectiveDate > curr.effectiveDate ? prev : curr
      })

    setSelectedContract(selContract)
    setSelectedDeal(selDeal)

    setIsLoading(false)
  }, [api, candidate._id])

  useEffect(() => {
    if (!candidate) return
    refresh()
  }, [candidate, refresh])

  useEffect(() => {
    onSelectedContractChange(selectedContract)
  }, [selectedContract, onSelectedContractChange])

  useEffect(() => {
    if (!selectedDeal || !user.hasPower(POWERS.customerSOWs)) return

    api
      .get(`/customerSOWs/in`, {
        params: { _id: deals.map((x) => x.customerSOWId) },
      })
      .then(({ data: allCustomerSOWs }) => {
        const selCustomerSOW = allCustomerSOWs.find(
          (x) => x._id === selectedDeal.customerSOWId
        )
        setSelectedCustomerSOW(selCustomerSOW)
      })
  }, [selectedDeal, api, deals, user])

  const handleContractChanged = (name, value) => {
    const newContract = { ...selectedContract }
    newContract[name] = value
    setSelectedContract(newContract)
  }

  // TODO: Revisar si esto no se puede elimitar y mover el hide al modal.
  const handleContractAdded = () => {
    setShowAddContractModal(false)
    refresh()
  }

  if (isLoading) {
    return null
  }

  if (contracts.length === 0) {
    return (
      <React.Fragment>
        <RequirePowers powers={[POWERS.createContract]}>
          <SideAddButton onClick={() => setShowAddContractModal(true)}>
            Add Contract 2.0
          </SideAddButton>
          <AddContractModal
            show={showAddContractModal}
            candidate={candidate}
            onCancel={() => setShowAddContractModal(false)}
            onSuccess={handleContractAdded}
          />
        </RequirePowers>
      </React.Fragment>
    )
  }

  if (!selectedContract || !selectedDeal) {
    return null
  }

  if (showHistory) {
    return (
      <ContractsHistory
        selectedDeal={selectedDeal}
        contracts={contracts}
        deals={deals}
        onGoBack={() => setShowHistory(false)}
        onSelect={({ contract, deal }) => {
          setSelectedContract(contract)
          setSelectedDeal(deal)
          setShowHistory(false)
        }}
      />
    )
  }

  return (
    <React.Fragment>
      <Section
        icon="handshake outline"
        title="Contract Information"
        menu={
          <ContractsMenu
            candidate={candidate}
            contracts={contracts}
            contractId={selectedContract._id}
            onContractAdded={refresh}
            onShowHistory={() => setShowHistory(true)}
            onDealUpdated={refresh}
            onContractTerminated={refresh}
          />
        }
      >
        <Deal
          deal={selectedDeal}
          key={selectedDeal._id}
          customerSOW={selectedCustomerSOW}
        />

        <Divider />

        <Contract
          contract={selectedContract}
          onContractChanged={handleContractChanged}
        />
      </Section>
    </React.Fragment>
  )
}

export default Contracts
