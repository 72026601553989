import React, { useState, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'

import { useAuthApi } from '../../store/mainContext'
import { useUser } from '../../pages/session/hooks'

const UserSelect = ({
  name,
  value,
  onChange,
  disabled,
  placeholder,
  multiple,
  search,
  orderBy,
  open,
  fluid,
  error,
  clerable = true,
}) => {
  const api = useAuthApi()
  const user = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    setIsLoading(true)
    api
      .get('users')
      .then(({ data }) => {
        const activeUsers = data.filter((x) => !x.disabled)
        setOptions(
          orderBy
            ? activeUsers.sort((a, b) => orderBy(a) - orderBy(b))
            : activeUsers
        )
      })
      .finally(() => setIsLoading(false))
  }, [api, orderBy])

  return (
    <Dropdown
      error={error}
      value={value}
      name={name}
      selection
      fluid={fluid}
      open={open}
      loading={isLoading}
      disabled={disabled}
      clearable={clerable}
      multiple={multiple}
      search={search}
      placeholder={placeholder}
      onChange={onChange}
      options={options.map((o) => ({
        key: o._id,
        value: o._id,
        text: user._id === o._id ? 'Me!' : o.name,
        image: { avatar: true, src: o.image },
      }))}
    />
  )
}

export default UserSelect
