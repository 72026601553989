import * as React from 'react'
import { Button, Confirm, Modal } from 'semantic-ui-react'

const ConfirmModal = ({
  show,
  title,
  question,
  highlight,
  onConfirm,
  onCancel,
}) => (
  <Confirm
    open={show}
    header={title}
    content={
      <Modal.Content>
        <p>{question}</p>

        {highlight && (
          <p className="highlight" style={{ fontSize: 17 }}>
            <b>{highlight}</b>
          </p>
        )}

        <p>Are you sure?</p>
      </Modal.Content>
    }
    onCancel={onCancel}
    closeOnDimmerClick={true}
    size="tiny"
    onConfirm={onConfirm}
    confirmButton={<Button negative>Yeap... Lets do it.</Button>}
    cancelButton={<Button basic>Ugh... no, no yet.</Button>}
  />
)

export default ConfirmModal
