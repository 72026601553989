import React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`

const rotate = keyframes`
  0% {
    transform:scale(0.6);
    opacity: 0.8;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  100% {
    transform:  scale(0.6);
    opacity: 0.8;
    animation-timing-function: ease-out;

  }
`

const Image = styled.img`
  animation: ${rotate} 2s infinite linear;
  margin: auto;
`

const PageLoader = ({ height }) => (
  <Container style={{ height }}>
    <Image className="mylogo" src="/img/128.png" />{' '}
  </Container>
)

export default PageLoader
