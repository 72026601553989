import * as React from 'react'
import { Button, ButtonGroup, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import EntityLink from '../../components/remoteValues/entityLink'
import UserName from '../../components/remoteValues/userName'
import DateFormat from '../../components/dateFormat'
import CheckButton from '../../components/form/checkButton'
import { isBeforeToday, isToday } from '../../utils'
import DataTable from '../../components/dataTable/dataTable'
import { useState } from 'react'
import ShowIf from '../../components/showIf'

const T = styled.span`
  text-decoration: line-through;
  color: var(--primary);
`

const D = styled.span`
  color: var(--red) !important;
`

const Today = styled.span`
  color: var(--green);
`

const TaskRow = ({
  task,
  candidate,
  contact,
  company,
  position,
  onEdit,
  onDone,
  onRemove,
}) => {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)

  const [, setIsRemoving] = useState(false)

  const TdBase = ({ children, align }) => (
    <DataTable.Td align={align} valign="top" paddingTop={12} paddingBottom={12}>
      {children}
    </DataTable.Td>
  )
  const NotDoneTd = ({ children, align }) => (
    <TdBase align={align}>{children}</TdBase>
  )
  const DueTd = ({ children, align }) => (
    <TdBase align={align}>
      <D>{children}</D>
    </TdBase>
  )
  const TodayTd = ({ children, align }) => (
    <TdBase align={align}>
      <Today>{children}</Today>
    </TdBase>
  )
  const DoneTd = ({ children, align }) => (
    <TdBase align={align}>
      <T>{children}</T>
    </TdBase>
  )

  let Td = isBeforeToday(task.dueDate) ? DueTd : NotDoneTd
  Td = isToday(task.dueDate) ? TodayTd : Td

  Td = task.isDone ? DoneTd : Td

  return (
    <tr>
      <DataTable.Td align="center">
        <CheckButton value={task.isDone} onChange={() => onDone(task)} />
      </DataTable.Td>
      <Td>
        <div onClick={() => onEdit(task)}> {task.subject}</div>
      </Td>

      <Td>
        <div
          onClick={() => onEdit(task)}
          dangerouslySetInnerHTML={{ __html: task.details }}
        ></div>
      </Td>

      <Td>
        <EntityLink
          entity={candidate || contact || company || position}
          collection={task.relatedCollection}
          defaultText="Nothing related."
        />
      </Td>

      <Td align="center">
        <div onClick={() => onEdit(task)}>
          <DateFormat date={task.dueDate} explain />
        </div>
      </Td>

      <Td>
        <div onClick={() => onEdit(task)}>
          <UserName id={task.createdBy} short />
        </div>
      </Td>

      <Td align="right">
        <ShowIf if={showConfirmRemove}>
          <ButtonGroup size="tiny">
            <Button
              color="red"
              icon
              onClick={() => {
                setIsRemoving(true)
                setShowConfirmRemove(false)
                onRemove(task)
              }}
            >
              Yes, Remove.
            </Button>
            <Button
              icon
              onClick={() => {
                setShowConfirmRemove(false)
              }}
            >
              No, go back.
            </Button>
          </ButtonGroup>
        </ShowIf>

        <ShowIf if={!showConfirmRemove}>
          {/* <Button size='tiny' basic icon onClick={(e) => {e.preventDefault(); window.open(`mailto:${candidate.email}?cc=talent@southteams.com`);}}><Icon name="mail" /></Button> */}

          <Button size="tiny" basic icon onClick={() => onEdit(task)}>
            <Icon name="pencil alternate" />
          </Button>
          <Button
            size="tiny"
            color="red"
            icon
            onClick={() => setShowConfirmRemove(true)}
          >
            <Icon name="trash alternate outline" />
          </Button>
        </ShowIf>
      </Td>
    </tr>
  )
}

export default TaskRow
